import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Editor, EditorTextChangeEvent } from 'primeng/editor';
import { ColorsConfig } from 'src/app/core/constants/product.config';

@Component({
  selector: 'app-editor-custom',
  templateUrl: './editor-custom.component.html',
  styleUrls: ['./editor-custom.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorCustomComponent {
  @ViewChild('editor') quillEditor: Editor;
  @Input({ required: true }) productForm: any;
  @Input() isValidDes = true;
  @Input() isViewMode: boolean = false;

  @Output() isValidDesChange = new EventEmitter();
  protected readonly managementPrefix = 'section-product-n-food-management.';
  readonly LIMIT_DESCRIPTION = 3000;
  colors = ColorsConfig;
  length = 0;
  isHint = false;

  onChangeTextEditor(ev: EditorTextChangeEvent) {
    this.isHint = true;
    this.length = ev.textValue.length;
    const length = ev.textValue.length;
    if (length > this.LIMIT_DESCRIPTION - 1) {
      this.quillEditor.quill.deleteText(this.LIMIT_DESCRIPTION - 1, length);
      this.quillEditor.onModelTouched();
    } else {
      this.isValidDes = true;
      this.isValidDesChange.emit(this.isValidDes);
    }

    if (length > this.LIMIT_DESCRIPTION) {
      this.isValidDes = false;
      this.isValidDesChange.emit(this.isValidDes);
    } else {
      this.isValidDes = true;
      this.isValidDesChange.emit(this.isValidDes);
    }
  }
}
