import { Injectable } from '@angular/core';
import { Toast } from 'src/app/core/utils/toast.util';
@Injectable({
  providedIn: 'root',
})
export class ErrorHandler {
  constructor(private toast: Toast) {}

  handle(error: any, message?: string, title?: string) {
    let err = error?.error;
    while (err?.error) err = err?.error;

    const code = err?.code || err?.status || error?.code || error?.status;
    const codeString: string = code?.toString() || '';

    let tmpTitle = codeString.startsWith('4')
      ? 'Invalid action'
      : codeString.startsWith('5')
      ? 'Server issue'
      : 'Issue happens';

    let tmpMessage = err?.message || error?.message;
    tmpMessage =
      tmpMessage.length > 60
        ? 'Something went wrong. Please try again later.'
        : tmpMessage;

    this.toast.error(title || tmpTitle, message || tmpMessage);
  }
}
