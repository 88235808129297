import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { DropdownModule } from 'primeng/dropdown';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ProductCategoriesModule } from './features/product-categories/product-categories.module';
import {
  ApiModule as StockApiModule,
  BASE_PATH as STOCK_PATH,
} from '@soctrip/angular-stock-service';
import {
  ApiModule as AngularCatalogApiModule,
  BASE_PATH as ANGULAR_CATALOG_PATH,
} from '@soctrip/angular-catalog-service';
import {
  ApiModule as PromotionApiModule,
  BASE_PATH as PROMOTION_PATH,
} from '@soctrip/angular-promotion-service';
import {
  ApiModule as AngularShopApiModule,
  BASE_PATH as ANGULAR_SHOP_PATH,
} from '@soctrip/angular-shop-service';
import {
  ApiModule as OrderApiModule,
  BASE_PATH as ORDER_PATH,
} from '@soctrip/angular-order-service';
import {
  ApiModule as PaymentApiModule,
  BASE_PATH as PAYMENT_PATH,
} from '@soctrip/angular-payment-service';
import {
  ApiModule as ShipmentApiModule,
  BASE_PATH as SHIPMENT_PATH,
} from '@soctrip/angular-shipment-service';
import {
  ApiModule as StorageApiModule,
  BASE_PATH as STORAGE_PATH,
} from '@soctrip/angular-storage-service';
import {
  ApiModule as AdvertisingApiModule,
  BASE_PATH as ADVERTISING_PATH,
} from '@soctrip/angular-advertising-service';
import {
  ApiModule as LivestreamApiModule,
  BASE_PATH as LIVESTREAM_PATH,
} from '@soctrip/angular-livestream-service';
import {
  ApiModule as ShopAnalyticApiModule,
  BASE_PATH as SHOP_ANALYTIC_PATH,
} from '@soctrip/angular-shop-analytic-service';
import {
  ApiModule as WalletApiModule,
  BASE_PATH as WALLET_PATH,
} from '@soctrip/angular-ecommerce-wallet-service';
import {
  ApiModule as FinanceApiModule,
  BASE_PATH as FINANCE_PATH,
} from '@soctrip/angular-ecommerce-finance-service';

import { environment } from 'src/environments/environment';
import { Locales } from './core/constants/locales.config';

const enum ServiceName {
  STOCK = 'STOCK',
  CATALOG = 'CATALOG',
  SHOP = 'SHOP',
  STORAGE = 'STORAGE',
  ORDER = 'ORDER',
  PROMOTION = 'PROMOTION',
  PAYMENT = 'PAYMENT',
  SHIPMENT = 'SHIPMENT',
  ADVERTISING = 'ADVERTISING',
  LIVESTREAM = 'LIVESTREAM',
  SHOP_ANALYTIC = 'SHOP_ANALYTIC',
  WALLET = 'WALLET',
  FINANCE = 'FINANCE',
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DropdownModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ProductCategoriesModule,
    StockApiModule,
    AngularCatalogApiModule,
    OrderApiModule,
    PromotionApiModule,
    AngularShopApiModule,
    PaymentApiModule,
    ShipmentApiModule,
    StorageApiModule,
    AdvertisingApiModule,
    LivestreamApiModule,
    ShopAnalyticApiModule,
    WalletApiModule,
    FinanceApiModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: ANGULAR_CATALOG_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.CATALOG]
      }`,
    },
    {
      provide: STOCK_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.STOCK]
      }`,
    },
    {
      provide: ORDER_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.ORDER]
      }`,
    },
    {
      provide: PROMOTION_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.PROMOTION]
      }`,
    },
    {
      provide: ANGULAR_SHOP_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.SHOP]
      }`,
    },
    {
      provide: PAYMENT_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.PAYMENT]
      }`,
    },
    {
      provide: SHIPMENT_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.SHIPMENT]
      }`,
    },
    {
      provide: STORAGE_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.STORAGE]
      }`,
    },
    {
      provide: ADVERTISING_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.ADVERTISING]
      }`,
    },
    {
      provide: LIVESTREAM_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.LIVESTREAM]
      }`,
    },
    {
      provide: SHOP_ANALYTIC_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.SHOP_ANALYTIC]
      }`,
    },
    {
      provide: WALLET_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.WALLET]
      }`,
    },
    {
      provide: FINANCE_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.[ServiceName.FINANCE]
      }`,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.initI18n();
  }
  private initI18n() {
    this.translate.addLangs(Locales);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
