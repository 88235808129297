<!-- Frame -->
<div *ngIf="url" class="relative h-full">
  <img
    [src]="url"
    [alt]="textDescription + 'upload-image' | translate | sentenceCase"
    class="inline-block w-full h-full min-w-[2rem] min-h-[2rem] object-cover object-center"
    onerror="this.src=defaultUrl"
  />

  <i
    (click)="removeFile()"
    class="pi pi-times absolute right-1 top-1 p-1 text-base text-gray-500 cursor-pointer bg-gray-50/40 hover:bg-gray-50/75 rounded-full"
  >
  </i>
</div>

<!-- Drop and browse file -->
<label
  *ngIf="!url"
  for="file-drop-zone"
  class="drag-drop"
  appDragAndDrop
  (filesDropped)="handleDrop($event)"
  class="flex items-center justify-center w-full h-full border-2 border-dashed rounded-lg hover:bg-gray-100 cursor-pointer select-none
  {{ isError ? 'border-error-400' : 'border-gray-300' }}
  "
>
  <div class="grid gap-1 justify-center subtitle1">
    <span class="flex items-center justify-center">
      <i class="pi pi-image text-gray-500"></i>
    </span>

    <div *ngIf="showText" class="grid">
      <span class="flex gap-1 justify-center text-sm text-gray-600">
        <span class="text-blue-500 hover:underline">
          {{ textDescription + "click-to-upload" | translate | sentenceCase }}
        </span>
        <span>
          {{ textDescription + "or-drag-and-drop" | translate }}
        </span>
      </span>
      <span class="text-xs">
        PNG {{ "common.or" | translate }} JPG
        {{ "common.max" | translate }}
      </span>
    </div>
  </div>

  <input (change)="selectFile($event)" id="file-drop-zone" type="file" hidden />
</label>

<div *ngIf="isError" class="text-sm text-error-300 text-center pt-1">
  {{ textDescription + "image-is-invalid" | translate | sentenceCase }}
</div>
