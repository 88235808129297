import { Component, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { StockProductAttributeControllerService } from '@soctrip/angular-stock-service';
import { Subject, switchMap } from 'rxjs';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { AttributeGet } from 'src/app/core/models/interfaces/product/product-detail';
import { ProductFormType } from '../../pages/product-edit/product-edit.model';
import { MessageService } from 'primeng/api';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { OverlayPanel } from 'primeng/overlaypanel';

enum AttributeEnum {
  KEY = 'key',
  VALUE = 'value',
}

@Component({
  selector: 'app-product-attribute',
  templateUrl: './product-attribute.component.html',
  styleUrls: ['./product-attribute.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductAttributeComponent {
  readonly unsubscribe$ = new Subject();
  readonly SoctripIcons = SoctripIcons;
  readonly AttributeEnum = AttributeEnum;
  readonly actionPrefix = 'section-action.';
  readonly managementPrefix = 'section-product-n-food-management.';
  readonly descriptionPrefix = 'text-description.';
  @Input({ required: true }) productForm: ProductFormType;
  @Input() isViewMode: boolean = false;

  attributes: AttributeGet[] = [];

  constructor(
    private attributeService: StockProductAttributeControllerService,
    private messageService: MessageService,
    private translator: CustomTranslateService,
  ) {}

  ngOnInit() {
    this.attributeService.productAttributesGet().subscribe({
      next: (res) => {
        this.attributes = res.data ?? [];
      },
    });
  }

  onSelectAttribute(attribute: AttributeGet, index: number, type: AttributeEnum) {
    if (type == AttributeEnum.KEY) {
      this.productForm.attributes[index].id = attribute.id;
      this.productForm.attributes[index].keyword = attribute.value;
      this.productForm.attributes[index].value = attribute.value;

      this.productForm.attributes[index].valueAttr.parent_id = attribute.id;
      this.productForm.attributes[index].valueAttr.value = '';
    } else {
      this.productForm.attributes[index].valueAttr.id = attribute.id;
      this.productForm.attributes[index].valueAttr.keyword = attribute.value;
      this.productForm.attributes[index].valueAttr.value = attribute.value;
    }
  }

  addNewAttributes() {
    this.productForm.attributes.push({
      id: '',
      keyword: '',
      value: '',
      is_parent: true,

      valueAttr: {
        id: '',
        keyword: '',
        value: '',
        parent_id: '',
        is_parent: false,
      },
    });
  }

  createAttribute(i: number, type: AttributeEnum) {
    const attribute = this.productForm.attributes[i];
    let body;
    if (type == AttributeEnum.KEY) {
      body = {
        value: attribute.keyword,
        is_parent: true,
      };
    } else {
      body = {
        value: attribute.valueAttr.keyword,
        is_parent: false,
        parent_id: attribute.id,
      };
    }
    this.attributeService
      .productAttributesPost(body)
      .pipe(
        switchMap((res) => {
          if (type == AttributeEnum.KEY) {
            this.productForm.attributes[i].id = res.data.id;
            this.productForm.attributes[i].value = res.data.value;
          } else {
            this.productForm.attributes[i].valueAttr.id = res.data.id;
            this.productForm.attributes[i].valueAttr.value = res.data.value;
          }

          return this.attributeService.productAttributesGet();
        }),
      )
      .subscribe({
        next: (res) => {
          this.attributes = res.data;
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            detail: this.translator.transform(this.managementPrefix + 'text-description.create-attribute-failed'),
          });
        },
      });
  }

  getAttributes(idx: number, type: AttributeEnum) {
    const attribute = { ...this.productForm.attributes[idx] };

    if (type == 'value') {
      return (this.attributes.find((attr) => attr.id == attribute.id)?.values ?? []).filter((attr) =>
        attr.value.toLowerCase().includes(attribute.valueAttr.keyword.toLowerCase()),
      );
    } else {
      return this.attributes.filter((attr) => attr.value.toLowerCase().includes(attribute.keyword.toLowerCase()));
    }
  }

  isAddNew(idx: number, type: AttributeEnum) {
    const attribute = this.productForm.attributes[idx];
    if (type == AttributeEnum.KEY) {
      const keyword = attribute.keyword.trim();
      if (!keyword || this.attributes.map((attr) => attr.value).includes(keyword)) {
        return false;
      } else {
        return true;
      }
    } else {
      const keyword = attribute.valueAttr.keyword.trim();
      if (!keyword) {
        return false;
      } else {
        return true;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
