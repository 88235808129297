<div
  *ngIf="totalRecords > RowsPerPageOptions[0] && !isLoading"
  class="flex gap-4 px-6 py-2 bg-white justify-between"
  [ngClass]="stylesClass"
>
  <!-- ROW PAGINATION -->
  <div class="flex items-center gap-2">
    <span class="text-sm">
      {{ "common.rows-per-page" | translate | sentenceCase }}:
    </span>
    <p-dropdown
      (ngModelChange)="onRowsChange($event)"
      [ngModel]="pagination.rows"
      [options]="RowsPerPageOptions"
      appendTo="body"
    ></p-dropdown>
    <!-- <span class="text-sm">
      {{ "common.rows" | translate }}
    </span> -->
  </div>

  <!-- PAGE PAGINATION -->
  <div class="flex items-center gap-2">
    <span *ngIf="!isCompact" class="text-sm">
      {{ pagination.rows * pagination.page + 1 }} -
      {{
        pagination.rows * pagination.page + pagination.rows > totalRecords
          ? totalRecords
          : pagination.rows * pagination.page + pagination.rows
      }}
      {{ "common.of" | translate }}
      {{ totalRecords }}
    </span>
    <p-paginator
      (onPageChange)="onPageChange($event)"
      [first]="pagination.rows * pagination.page"
      [rows]="pagination.rows"
      [totalRecords]="totalRecords"
      [showFirstLastIcon]="false"
    ></p-paginator>
  </div>
</div>
