export enum SortEnum {
  NAME_DESC = '-s_name',
  NAME_ASC = 's_name',
  QUANTITY_DESC = '-quantity|double',
  QUANTITY_ASC = 'quantity|double',
  PRICE_DESC = '-price|double',
  PRICE_ASC = 'price|double',
  UPDATED_AT = '-updated_at|time',
}

export enum TabEnum {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  DRAFT = 'DRAFT',
  TRASH = 'TRASH',
  SALE_DIRECT = 'SALE_DIRECT',
  IN_REVIEW = 'IN_REVIEW',
  BLOCKED = 'BLOCKED',
  WARNING = 'WARNING',
}

export enum ExportAttributeEnum {
  GENERAL = 'GENERAL_INFOMATION',
  QUANTITY_AND_SELLING_PRICE = 'QUANTITY_AND_SELLING_PRICE',
  PRODUCT_AND_VARIANT_IMAGES = 'PRODUCT_AND_VARIANT_IMAGES',
  SHIPMENT = 'WEIGHT_AND_SHIPPING',
}

export enum ExportTypeEnum {
  ALL = 'ALL_PRODUCT',
  CATEGORY = 'FIND_BY_CATEGORY',
}
