import { environment } from 'src/environments/environment';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import * as moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';

export const NotificationType = {
  RequestNewFriend: 'request_new_friend',
  TagYouInPost: 'tag_you_in_post',
  TagYouInComment: 'tag_you_in_comment',
  UploadEvent: 'upload_event',
  NewPost: 'new_post',
  ReactOnPost: 'react_on_post',
  Event: 'event',
  Livestream: 'livestream',
  Memory: 'memory',
  Gift: 'gift',
};

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class HeaderNotificationsComponent implements AfterViewInit {
  readonly environment = environment;
  overlayVisible = false;
  notificationList: any[];
  badgeRead: any;
  @Input() mobileOverlayVisible = false;
  @Output() mobileOverlayVisibleChange = new EventEmitter();

  showButtonIndex: number | null = null;
  indexOverlay: number;

  @ViewChildren('overlayPanelElement') overlayPanels!: QueryList<OverlayPanel>;

  constructor(private notificationsService: NotificationsService) {}

  ngAfterViewInit(): void {
    this.getNotificationList();
  }

  @HostListener('window:scroll', ['$event'])
  handleHideOverlayPanel() {
    if (typeof this.indexOverlay === 'number') {
      this.overlayPanels.toArray()[this.indexOverlay].hide();
    }
  }

  isSingleDigit(number: number): boolean {
    return !isNaN(number) && number >= 0 && number <= 9;
  }

  closeDialog() {
    this.mobileOverlayVisibleChange.emit(false);
  }


  getNotificationList() {
    const pageNum = 0;
    const pageSize = 100;
    this.notificationsService
      .getNotifications(this.environment.API_URL, pageNum, pageSize)
      .subscribe((data) => {
        if (data && data.data) {
          const notifications = data.data;
          notifications.sort((a: any, b: any) => {
            return +new Date(a.send_time) - +new Date(b.send_time);
          });
          this.notificationList = notifications.reverse();
          let countNoti = '0';
          if (data.totalElement >= 100) {
            countNoti = '99+';
          } else if (data.totalElement <= 99) {
            countNoti = data.totalElement;
          }
          this.badgeRead = countNoti;
        }
      });
  }

  markAllReadNotification(event: Event) {
    event.stopPropagation();
    this.notificationsService
      .markAllReadNotification(this.environment.API_URL)
      .subscribe(() => {
        this.getNotificationList();
      });
  }

  navigateToAccountSettings() {
    window.location.href =
      this.environment.LOGIN_URL + '/account-settings?setting=notifications';
  }

  onMouseEnter(index: number) {
    this.showButtonIndex = index;
  }

  onMouseLeave() {
    this.showButtonIndex = null;
  }

  getAvatar(id: string) {
    if (!id) {
      return '';
    }
    const isPath = id.startsWith('/');
    const webPath = isPath ? '' : '/storage/files/web/';
    const addWebp = isPath ? '' : '.webp';

    return `${this.environment.API_URL}${webPath}${id}${addWebp}`;
  }

  getColorIcon(notificationType: string): {
    background: string;
    color: string;
  } {
    switch (notificationType) {
      case NotificationType.RequestNewFriend:
        return {
          background: 'bg-palette-yellow-100',
          color: 'text-palette-yellow-500',
        };
      case NotificationType.TagYouInPost:
        return {
          background: 'bg-palette-amber-100',
          color: 'text-palette-amber-500',
        };
      case NotificationType.TagYouInComment:
        return {
          background: 'bg-branding-primary-100',
          color: 'text-branding-primary-500',
        };
      case NotificationType.UploadEvent:
        return {
          background: 'bg-palette-rose-100',
          color: 'text-palette-rose-500',
        };
      case NotificationType.NewPost:
        return {
          background: 'bg-palette-violet-100',
          color: 'text-palette-violet-500',
        };
      case NotificationType.ReactOnPost:
        return {
          background: 'bg-palette-fuchsia-100',
          color: 'text-palette-fuchsia-500',
        };
      case NotificationType.Event:
        return {
          background: 'bg-palette-rose-100',
          color: 'text-palette-rose-500',
        };
      case NotificationType.Livestream:
        return {
          background: 'bg-palette-red-100',
          color: 'text-palette-red-500',
        };
      case NotificationType.Memory:
        return {
          background: 'bg-palette-teal-100',
          color: 'text-palette-teal-500',
        };
      case NotificationType.Gift:
        return {
          background: 'bg-palette-orange-100',
          color: 'text-palette-orange-500',
        };
      default:
        return {
          background: 'bg-branding-primary-100',
          color: 'text-branding-primary-500',
        };
    }
  }

  incrementBadgeCount() {
    this.badgeRead += 1;
  }

  decrementBadgeCount() {
    if (this.badgeRead > 0) {
      this.badgeRead -= 1;
    }
  }

  updateBadgeCount(data: any) {
    if (data && data._read) {
      this.decrementBadgeCount();
    } else {
      this.incrementBadgeCount();
    }
  }

  toggleNotification(id: any, event: Event) {
    event.stopPropagation();
    this.notificationsService
      .toggleReadNotification(this.environment.API_URL, id)
      .subscribe((data) => {
        this.updateBadgeCount(data);
        this.getNotificationList();
      });
    const notificationIndex = this.notificationList.findIndex(
      (item) => item.id === id,
    );
    if (notificationIndex !== -1) {
      this.notificationList[notificationIndex]._read =
        !this.notificationList[notificationIndex]._read;
    }
  }

  navigationUrl(specific_type: any,isRead: any, url: any, id: any, event: Event) {
     !isRead && this.toggleNotification(id, event);
    this.overlayVisible = false;
    localStorage.setItem('groupDetailEvent', 'active');
    localStorage.setItem('activeIndexGroup', '3');

    if(url.includes('https://')){
      window.location.href = url
    }else{
      if(specific_type=== 'APPROVED') {
        window.location.href = this.environment.FE_URL_SALE_NO_PREFIX + url;
      } else {
        window.location.href = this.environment.LOGIN_URL + url;
      }
    }    
  }

  calculateFromNow(date: any) {
    return moment(date).fromNow();
  }

  handleNotificationDeletion(isRead: boolean) {
    if (!isRead) {
      this.decrementBadgeCount();
    }
  }

  deleteNotification(id: any, event: Event, isRead: boolean) {
    event.stopPropagation();
    this.notificationsService
      .deleteNotification(this.environment.API_URL, id)
      .subscribe((data) => {
        this.handleNotificationDeletion(isRead);
        this.getNotificationList();
      });
  }

  processNotificationAction(ctaURL: any, id: any) {
    this.notificationsService
      .callToActionNotification(this.environment.API_URL, ctaURL)
      .subscribe(() => {
        this.notificationsService
          .completeCTANotification(this.environment.API_URL, id)
          .subscribe(() => {
            this.getNotificationList();
          });
      });
  }
}
