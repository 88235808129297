import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResponseType } from '../models/interfaces/common';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private http: HttpClient) {}

  // getAdminList(pageNum?: number, pageSize?: number) {
  //   return this.http.get(
  //     `${environment.BE_URL}user/admin?pageNum=${pageNum}&pageSize=${pageSize}`,
  //   );
  // }

  getUserList(pageNum: number, pageSize: number, keyword: string = '') {
    return this.http.get(
      `${environment.BE_URL}user/search-v2?pageNum=${pageNum}&pageSize=${pageSize}&textSearch=${encodeURIComponent(
        keyword,
      )}`,
    );
  }

  getUserPermissions(userId: string) {
    return this.http.get<ResponseType<string[]>>(
      `${environment.BE_URL}user-permission/api-permissions/users/${userId}/permissions`,
    );
  }

  // getAccountPermissionManager(
  //   shopId: string,
  //   pageNum?: number,
  //   pageSize?: number,
  // ) {
  //   return this.http.get(
  //     `${environment.BE_URL}user-permission/shops/my-user-permission/${shopId}?pageNum=${pageNum}&pageSize=${pageSize}`,
  //   );
  // }

  // updateAccountsPermissionManager(shopId: string, body: UserPermissionUpdate) {
  //   return this.http.put(
  //     `${environment.BE_URL}user-permission/shops/${shopId}/users`,
  //     body,
  //   );
  // }

  // addAccountsPermissionManager(
  //   shopId: string,
  //   userIds: string[],
  //   roles: RoleEnum[],
  // ) {
  //   const userIdsPath = userIds
  //     .map((id) => {
  //       return `user_ids=${id}`;
  //     })
  //     .join('&');
  //   return this.http.post(
  //     `${environment.BE_URL}user-permission/shops/${shopId}/users?${userIdsPath}`,
  //     roles,
  //   );
  // }

  // deleteAccountPermissionManager(shopId: string, userIds: string[]) {
  //   const ids = userIds.map((userId) => `ids=${userId}`).join('&');
  //   return this.http.delete(
  //     `${environment.BE_URL}user-permission/shops/${shopId}?${ids}`,
  //   );
  // }

  // getUserRole(shopId: string) {
  //   return this.http.get(
  //     `${environment.BE_URL}user-permission/shops/${shopId}/my-users/role`,
  //   );
  // }

  // getUserPermission(shopId: string) {
  //   return this.http.get(
  //     `${environment.BE_URL}user-permission/shops/${shopId}/my-users/permissions`,
  //   );
  // }
}
