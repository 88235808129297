<div ngModelGroup="product_attribute" class="bg-white rounded-lg border px-4 py-6">
  <h3 class="mb-6 font-semibold">
    {{ managementPrefix + "product-detail" | translate | sentenceCase }}
  </h3>
  <div class="flex flex-col gap-2">
    <label class="text-gray-700 text-sm mb-4" htmlFor="description">
      {{ managementPrefix + "text-description.product-detail-suggestion" | translate | sentenceCase }}
    </label>
    <!-- ATTRIBUTES TABLE FORM -->
    <div class="border rounded mb-2" *ngIf="productForm.attributes.length > 0">
      <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="productForm.attributes">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-xs font-medium text-gray-600">
              {{ "common.attribute" | translate | sentenceCase }}
            </th>
            <th class="text-xs font-medium text-gray-600">
              {{ "common.value" | translate | sentenceCase }}
            </th>
            <th class="text-xs font-medium text-gray-600 w-20 !text-center">
              {{ "common.action" | translate | sentenceCase }}
            </th>
          </tr>
        </ng-template>
        <ng-template let-i="rowIndex" pTemplate="body">
          <tr>
            <td class="content-start">
              <input
                [readOnly]="isViewMode"
                [name]="'attribute_key' + i"
                [required]="true"
                class="h-11 w-full"
                pInputText
                type="text"
                placeholder="{{ managementPrefix + 'enter-key-attribute' | translate }}"
                [(ngModel)]="productForm.attributes[i].keyword"
                (click)="!isViewMode ? keyOverplayPanel.toggle($event) : true"
              />
              <p-overlayPanel #keyOverplayPanel styleClass="attribute-overplayPanel">
                <div class="w-full rounded-lg bg-white min-w-[300px]">
                  <ul class="max-h-52 overflow-y-auto">
                    <li
                      *ngFor="let attribute of getAttributes(i, AttributeEnum.KEY)"
                      class="leading-10 h-10 hover:bg-gray-100 cursor-pointer px-6 text-sm"
                      (click)="onSelectAttribute(attribute, i, AttributeEnum.KEY); keyOverplayPanel.hide()"
                    >
                      {{ attribute.value }}
                    </li>
                    <li
                      *ngIf="isAddNew(i, AttributeEnum.KEY)"
                      (click)="createAttribute(i, AttributeEnum.KEY); keyOverplayPanel.hide()"
                      class="leading-10 h-10 my-2 hover:bg-gray-100 cursor-pointer px-6 text-sm"
                    >
                      {{ productForm.attributes[i].keyword }} ({{ managementPrefix + "new-attribute" | translate }})
                    </li>
                  </ul>
                </div>
              </p-overlayPanel>
            </td>
            <td>
              <input
                [readOnly]="isViewMode"
                [disabled]="!productForm.attributes[i].id"
                [name]="'attribute_value' + i"
                [required]="true"
                class="h-11 w-full"
                pInputText
                type="text"
                placeholder="{{ managementPrefix + 'enter-value-attribute' | translate }}"
                [(ngModel)]="productForm.attributes[i].valueAttr.keyword"
                (click)="!isViewMode ? valueOverplayPanel.toggle($event) : true"
              />
              <p-overlayPanel #valueOverplayPanel styleClass="attribute-overplayPanel">
                <div class="w-full rounded-lg bg-white shadow min-w-[300px]">
                  <ul class="max-h-52 overflow-y-auto">
                    <li
                      *ngFor="let attribute of getAttributes(i, AttributeEnum.VALUE)"
                      class="leading-10 h-10 hover:bg-gray-100 cursor-pointer px-6 text-sm"
                      (click)="onSelectAttribute(attribute, i, AttributeEnum.VALUE); valueOverplayPanel.hide()"
                    >
                      {{ attribute.value }}
                    </li>

                    <li
                      *ngIf="isAddNew(i, AttributeEnum.VALUE)"
                      (click)="createAttribute(i, AttributeEnum.VALUE); valueOverplayPanel.hide()"
                      class="leading-10 h-10 my-2 hover:bg-gray-100 cursor-pointer px-6 text-sm"
                    >
                      {{ productForm.attributes[i].valueAttr.keyword }} ({{
                        managementPrefix + "new-attribute" | translate
                      }})
                    </li>
                  </ul>
                </div>
              </p-overlayPanel>
            </td>
            <td class="!text-center">
              <span
                (click)="!isViewMode && productForm.attributes.splice(i, 1)"
                class="{{ SoctripIcons.TRASH_01 }} text-xl cursor-pointer text-error-500 {{
                  isViewMode ? 'text-gray-400 opacity-20' : ''
                }}"
              ></span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="w-full flex items-center justify-start">
      <button
        class="flex items-center gap-1 justify-start text-blue-700 text-sm font-semibold {{
          isViewMode ? 'hidden' : ''
        }}"
        (click)="addNewAttributes()"
      >
        <i class="pi pi-plus text-base"></i>
        {{ managementPrefix + "add-new-attribute" | translate | sentenceCase }}
      </button>
    </div>
  </div>
</div>
