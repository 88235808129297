import { Component, Input } from '@angular/core';
import { FileService } from 'src/app/core/services/file.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentFormType } from '../../pages/product-edit/product-edit.model';
@Component({
  selector: 'app-attachment-display',
  templateUrl: './attachment-display.component.html',
  styleUrls: ['./attachment-display.component.scss'],
})
export class AttachmentDisplayComponent {
  @Input({ required: true }) attachment: AttachmentFormType;
  @Input() isControlsVideo = false;

  constructor(
    public fileService: FileService,
    private sanitizer: DomSanitizer,
  ) {}

  checkIsImage(data: any) {
    const imageUri = `${environment.BE_URL}${environment.SERVICES?.['STORAGE']}/files`;
    return data.type.indexOf('image') > -1 || (data?.id.indexOf(imageUri) > -1 && data?.id.indexOf('webp') > -1);
  }

  getSrcYoutube(link: string) {
    const id = this.fileService.getYoutubeId(link);
    const url = 'https://www.youtube.com/embed/' + id;
    const params = this.isControlsVideo ? '' : '?controls=0&amp;';
    return this.sanitizer.bypassSecurityTrustResourceUrl(url + params);
  }
}
