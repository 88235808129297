import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { Category } from '../../models/category';
import { Toast } from 'src/app/core/utils/toast.util';
import { Clipboard } from 'src/app/core/utils/clipboard.util';
import { OTHERS } from 'src/app/core/constants/category.config';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent implements OnChanges {
  @Input() categoryGlobalData: any;
  @Input() selectedNode?: Category;
  @Output() selectedNodeChange: EventEmitter<any> = new EventEmitter();

  categoryPrefix = 'section-product-n-food-category.';
  categoryDes = 'section-product-n-food-category.text-description.';

  categoryName = '';
  categoryTax: number;
  categoryGlobalId = '';
  categoriesGlobalList: any;
  selectedGlobalCategory?: any;
  checkCatagoryTax: boolean = false;

  others = OTHERS
  isSelectionDropped = false;

  breadCrumbNames: any[] = [];

  constructor(
    private clipBoard: Clipboard,
    private eRef: ElementRef,
  ) {
    this.clipBoard = new Clipboard(inject(Toast));
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Put the data in the first array element, prepare for multiple arrays
    if (this.categoryGlobalData) this.categoriesGlobalList = [this.categoryGlobalData];
    this.sortCategories();
    if (this.selectedNode) {
      const node = this.selectedNode;
      this.categoryName = node.name || '';
      this.categoryTax = node.shop_tax;
      if (this.categoryGlobalData) {
        this.selectedGlobalCategory = this.findGlobalCategory(node.global_id!);
        this.breadCrumbNames = this.getBreadCrumbNames(node.global_id!);
      }
    }
  }
// move Others category to end of array
  sortCategories() {
    this.categoriesGlobalList?.forEach((categories: any[]) => {
      categories.sort((a: any, b: any) => {
        if (a.name.toUpperCase() === this.others) return 1;
        if (b.name.toUpperCase() === this.others) return -1;
        return 0;
      });
    });
  }

  categoryNameChange(event: any) {
    this.selectedNode!.name = this.categoryName;
    this.selectedNodeChange.emit(this.selectedNode);
  }

  categoryTaxChange(event: any) {
    this.selectedNode!.shop_tax = this.categoryTax;
    this.selectedNodeChange.emit(this.selectedNode);
    if (this.categoryTax < 0 || this.categoryTax > 100) {
      this.checkCatagoryTax = true;
    } else {
      this.checkCatagoryTax = false;
    }
  }

  // Handle multiple array hover events when interacting with the UI
  hoverCategory(event: any, category: any) {
    event.stopPropagation();
    this.categoriesGlobalList.splice(category.depth + 1);
    if (category.sub_catalogs) this.categoriesGlobalList.push(category.sub_catalogs);
  }

  selectCategory(event: any, category: any) {
    event.stopPropagation();
    this.selectedGlobalCategory = category;
    if (category) {
      this.breadCrumbNames = this.getBreadCrumbNames(category.id);
      this.selectedNode!.global_id = this.selectedGlobalCategory.id;
    } else {
      this.breadCrumbNames = [];
      this.selectedNode!.global_id = undefined;
    }
    this.selectedNodeChange.emit(this.selectedNode);
    this.isSelectionDropped = false;
  }

  /**
   *
   * @param id the id of the global category
   * @param list the global category list
   * @returns the breadcrumb string names of the selected global category
   */
  getBreadCrumbNames(id: string, list: any[] = this.categoryGlobalData) {
    const array = this.getFlattenNestedCategory(id, list);
    return array.map((item: any) => item.name);
  }

  /**
   * Convert the nested selected global category list into an array list from the root parent to the child
   */
  getFlattenNestedCategory(id: string, list: any[] = this.categoryGlobalData) {
    let result = [];
    let node = this.findGlobalCategory(id, list);
    while (node) {
      result.unshift(node);
      node = this.findGlobalCategory(node.parent_id, list);
    }
    return result;
  }

  findGlobalCategory(id: string, list: any[] = this.categoryGlobalData): any {
    let result = undefined;
    for (const item of list) {
      if (item.id === id) return item;
    }
    for (const item of list) {
      const children = item.sub_catalogs;
      if (children?.length) result = this.findGlobalCategory(id, children);
      if (result) break;
    }
    return result;
  }

  copyToClipBoard(code: string) {
    this.clipBoard.copyText(code);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isSelectionDropped = false;
    }
  }
}
