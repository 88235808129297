import { IService } from '../interface/IEnvironment.interface';

export const PREFIX = {
  API_V1: 'api/v1',
  AVATAR_DIR: 'sso-service/static/images/avatars',
  DEV: 'dev',
  PROD: 'production',
  QA: 'qa',
  LOCAL: 'local',
};

export const SERVICES: IService = {
  STOCK: 'stock',
  CATALOG: 'catalog',
  SHOP: 'shop',
  STORAGE: 'storage',
  STORAGE_STREAMING: 'storage-streaming',
  ORDER: 'order',
  PROMOTION: 'promotion',
  PAYMENT: 'payment',
  SHIPMENT: 'shipment',
  ADVERTISING: 'advertising',
  LIVESTREAM: 'livestream',
  SHOP_ANALYTIC: 'shop-analytic',
  WALLET: 'ecommerce-wallet',
  FINANCE: 'ecommerce-finance',
};
