<!-- <p-tree
  *ngIf="categoriesData"
  [value]="categoriesData"
  class="overflow-y-auto"
  selectionMode="single"
  (onNodeSelect)="handleNodeSelect($event)"
>
  <ng-template let-node pTemplate="default">
    <div class="flex gap-2 text-sm items-center justify-between">
      <span class="h-full flex gap-2 items-center truncate">
        <img
          [src]="getImgWebp(node?.image?.id)"
          onerror="this.src='/assets/imgs/default/product.webp'"
          class="inline-block min-w-[2rem] w-8 h-8 object-cover object-center rounded-md"
        />
        <div class="flex flex-col justify-around">
          <span
            title="{{ node.name }}"
            class="truncate text-sm text-gray-700
            {{
              node === selectedNode
                ? 'font-semibold text-primary-700'
                : node.children?.length
                ? 'font-semibold text-gray-900'
                : 'font-medium'
            }}"
          >
            {{ node.name }}
          </span>
          <div class="flex gap-1 items-center" *ngIf="node?.code">
            <span class="truncate text-[11px] text-gray-500">{{ node.code }}</span>
            <span class="flex items-center" (click)="copyToClipBoard($event, node?.code)">
              <i class="sctr-icon-copy-03 text-base"></i>
            </span>
          </div>
        </div>
      </span>

      <span data-unselectNode="true" class="flex items-center min-w-max">
        <i
          data-unselectNode="true"
          class="switch {{ node?.is_used ? 'checked' : '' }}"
          (click)="handlePublicToggle($event, node)"
        ></i>
        <p-menu #menu data-unselectNode="true" [model]="items" [popup]="true" appendTo="body"></p-menu>
        <button
          data-unselectNode="true"
          class="flex items-center px-1 hover:text-primary-500"
          (click)="openMenuCommand($event, node, menu)"
        >
          <i data-unselectNode="true" class="pi pi-ellipsis-v"></i>
        </button>
      </span>
    </div>
  </ng-template>
</p-tree> -->

<p-orderList [value]="categoriesData" [dragdrop]="true" (onReorder)="onReorderCategory()">
  <ng-template let-product pTemplate="item">
    <p-tree
      *ngIf="product"
      [value]="[product]"
      class="overflow-y-auto"
      selectionMode="single"
      (onNodeSelect)="handleNodeSelect($event)"
    >
      <ng-template let-node pTemplate="default">
        <div class="flex gap-2 text-sm items-center justify-between">
          <span class="h-full flex gap-2 items-center truncate">
            <img
              [src]="getImgWebp(node?.image?.id)"
              onerror="this.src='/assets/imgs/default/product.webp'"
              class="inline-block min-w-[2rem] w-8 h-8 object-cover object-center rounded-md"
            />
            <div class="flex flex-col justify-around">
              <span
                title="{{ node.name }}"
                class="truncate text-sm text-gray-700
            {{
                  node === selectedNode
                    ? 'font-semibold text-primary-700'
                    : node.children?.length
                    ? 'font-semibold text-gray-900'
                    : 'font-medium'
                }}"
              >
                {{ node.name }}
              </span>
              <div class="flex gap-1 items-center" *ngIf="node?.code">
                <span class="truncate text-[11px] text-gray-500">{{ node.code }}</span>
                <span class="flex items-center" (click)="copyToClipBoard($event, node?.code)">
                  <i class="sctr-icon-copy-03 text-base"></i>
                </span>
              </div>
            </div>
          </span>

          <span data-unselectNode="true" class="flex items-center min-w-max">
            <i
              data-unselectNode="true"
              class="switch {{ node?.is_used ? 'checked' : '' }}"
              (click)="handlePublicToggle($event, node)"
            ></i>
            <p-menu #menu data-unselectNode="true" [model]="items" [popup]="true" appendTo="body"></p-menu>
            <button
              data-unselectNode="true"
              class="flex items-center px-1 hover:text-primary-500"
              (click)="openMenuCommand($event, node, menu)"
            >
              <i data-unselectNode="true" class="pi pi-ellipsis-v"></i>
            </button>
          </span>
        </div>
      </ng-template>
    </p-tree>
  </ng-template>
</p-orderList>
