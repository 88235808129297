import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { map, Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token =
      localStorage.getItem('accessToken') || localStorage.getItem('liveToken');
    const headers: HttpHeaders = request.headers.set(
      'Authorization',
      `Bearer ${token}`,
    );
    const authReq = request.clone({ headers });
    return next.handle(authReq).pipe(
      map((event) => {
        return event;
      }),
    );
  }
}
