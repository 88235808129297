import { Injectable, inject } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { Product } from '../models/product';
import ProductSampleData from './productSampleData';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class StockProductControllerService {
  httpClient = inject(HttpClient);
  private api: string = `${environment.BE_URL}${environment.SERVICES?.['STOCK']}`;

  getProductsMini(): Observable<Array<Product[]>> {
    return of(ProductSampleData.productsByCategory().slice(0, 5));
  }

  getProductsSmall() {
    return Promise.resolve(ProductSampleData.productsByCategory().slice(0, 10));
  }

  getProductsCategoriesList(): Observable<TreeNode[]> {
    return of(ProductSampleData.productsCategoriesList());
  }

  getProductsByCategory(): Observable<any[]> {
    return of(ProductSampleData.productsByCategory());
  }

  shopsMyShopCatalogSearchGet(
    shopId: string,
    pageNum: number = 0,
    pageSize: number = 10,
    filters: string = '',
    sort: string = '',
  ) {
    filters = filters && encodeURIComponent(filters);
    sort = sort && encodeURIComponent(sort);
    const curlString = `${this.api}/shops/my-shop/${shopId}/catalog/search?pageNum=${pageNum}&pageSize=${pageSize}&filters=${filters}&sort=${sort}`;
    return this.httpClient.get(curlString);
  }
}
