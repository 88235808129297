export enum RoleEnum {
  E_OWNER = 'E_OWNER',
  SUPER_ADMIN_SHOP = 'SHOP_ADMIN',
  E_ADMIN_SHOP = 'ADMIN_SHOP',
  E_PRODUCT_MANAGEMENT = 'E_PRODUCT_MANAGEMENT',
  E_PROMOTION_MANAGEMENT = 'E_PROMOTION_MANAGEMENT',
  E_TICKET_CHECK_IN = 'E_TICKET_CHECK_IN',
  E_TICKET_MANAGEMENT = 'E_TICKET_MANAGEMENT',
  E_LIVESTREAM_MANAGEMENT = 'E_LIVESTREAM_MANAGEMENT',
}
