<div>
  <div *ngIf="selectedProducts.length" class="border rounded overflow-hidden mb-6">
    <p-table
      [(selection)]="selectedProductsLocal"
      dataKey="id"
      [value]="selectedProducts"
      [tableStyle]="{ width: '100%' }"
      [globalFilterFields]="['name']"
      #table
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-between items-center">
          <h3 class="flex-1">
            {{ "common.product-list" | translate | sentenceCase }}
          </h3>
          <div class="w-full flex-1 flex gap-2">
            <div class="flex flex-1 md:justify-end">
              <div class="p-input-icon-left max-w-xs w-full">
                <i class="pi pi-search"></i>
                <input
                  class="h-[44px] !text-sm w-full"
                  type="text"
                  pInputText
                  [placeholder]="managementPrefix + 'search-product' | translate | sentenceCase"
                  (input)="onSearchProducts($event)"
                  [(ngModel)]="keyword"
                />
              </div>
            </div>
            <app-button
              *ngIf="selectedProductsLocal.length > 0"
              (onClick)="onDeleteProductLocal()"
              variant="danger"
              styleClass="border-orange-dark-600 text-orange-dark-600"
              [label]="'Delete selected (' + selectedProductsLocal.length + ')'"
            ></app-button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox [disabled]="isViewMode"></p-tableHeaderCheckbox>
          </th>
          <th class="text-sm !font-medium w-[400px]">
            {{ "common.product" | translate | sentenceCase }}
          </th>
          <th class="text-sm !font-medium">
            {{ "common.price" | translate | sentenceCase }}
          </th>
          <th class="text-sm !font-medium">
            {{ "common.stock" | translate | sentenceCase }}
          </th>
          <th *ngIf="!isViewMode" class="text-sm !font-medium">
            {{ "common.action" | translate | sentenceCase }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
        <tr>
          <td>
            <p-tableCheckbox [value]="product" [disabled]="isViewMode"></p-tableCheckbox>
          </td>
          <td class="text-sm !font-medium">
            <div class="flex items-center gap-2">
              <app-custom-image
                class="w-10 min-w-[40px] h-10 overflow-hidden rounded-full"
                [src]="product?.avatar?.id"
                alt="Product"
              />
              <span class="line-clamp-2 max-w-[400px]">{{ product.name }}</span>
            </div>
          </td>
          <td class="text-sm !font-medium">{{ product.price | currency: " " : "symbol" : "0.0-2" }}</td>
          <td class="text-sm !font-medium">{{ product.quantity }}</td>
          <td *ngIf="!isViewMode" class="text-sm !font-medium">
            <i (click)="onDeleteProduct(product.id)" class="pi pi-trash text-error-500 cursor-pointer"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div *ngIf="selectedProducts?.length == 0" class="bg-white w-full flex flex-col gap-2 items-center py-4">
          <img
            alt="Empty data"
            class="inline-block min-w-[5rem] w-20 h-20 object-cover object-center"
            src="assets/imgs/empty-data.svg"
          />
          <span class="text-gray-400 text-sm">
            {{ "common.no-data" | translate | sentenceCase }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr *ngIf="!!keyword">
          <td colspan="7">
            <span class="text-sm font-medium">{{
              managementPrefix + "no-products-found" | translate | sentenceCase
            }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <button
    *ngIf="!isViewMode"
    (click)="isModalAddProduct = true"
    class="flex items-center gap-1 justify-start text-blue-700 text-sm font-semibold"
  >
    <i class="pi pi-plus"></i>
    {{ labelAddProduct }}
  </button>
</div>

<app-add-product-dialog
  *ngIf="!isViewMode"
  [(isModal)]="isModalAddProduct"
  [selectedProducts]="selectedProducts"
  (selectedProductsChange)="onChangeSelectedProducts($event)"
></app-add-product-dialog>
