<div
  *ngIf="stateView === 'APP' && fetched"
  class="max-w-[1920px] mx-auto flex flex-col bg-gray-50"
>
  <!-- Virtual header has the same height with <header>, to support UI flows -->
  <header
    class="min-h-[3.125rem] max-h-[3.125rem] w-full bg-transparent border-transparent select-none"
  ></header>
  <!-- Virtual header -->

  <!-- HEADER, with proper z-index for another elements to pop up: dialog, tooltip, ... -->
  <app-header
    class="fixed z-20 w-full h-[3.125rem] max-w-[1920px] min-w-[480px] bg-white shadow-sm"
    [isSmallScreen]="isSmallScreen"
    [user]="user"
  ></app-header>

  <!-- Body -->
  <ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</div>

<div
  class="w-screen h-screen flex items-center justify-center"
  *ngIf="stateView !== 'APP' || !fetched"
>
  <p-progressBar
    mode="indeterminate"
    class="w-[14%]"
    [style]="{ height: '5px' }"
  ></p-progressBar>
</div>

<app-toast-message></app-toast-message>

<iframe
  style="display: none"
  id="socialLogin"
  [src]="iframeUrl"
  title="socialLogin"
  (load)="handleOnLoad()"
></iframe>

