<div class="grid gap-4">
  <p-table
    #addProductTable
    dataKey="id"
    [value]="productStockData"
    [(selection)]="selectedProducts"
    [tableStyle]="{ 'min-width': '20rem' }"
    styleClass="p-datatable-sm"
    (selectionChange)="handleSelection($event)"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-between gap-4 items-center">
        <span class="p-input-icon-left w-full flex items-center">
          <i class="pi pi-search"></i>
          <input
            #searchValue
            pInputText
            class="w-full flex items-center py-2.5 px-3.5 gap-2 self-stretch text-gray-500 border text-sm border-gray-300 rounded-lg"
            type="text"
            (input)="onSearchInputChange(searchValue.value)"
            [placeholder]="
              categoryPrefix + 'search-keyword' | translate | sentenceCase
            "
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>
          {{ "common.product" | translate | sentenceCase }}
        </th>
        <th>
          <div class="text-end min-w-max">
            {{ "common.price" | translate | sentenceCase }}
          </div>
        </th>
        <th>
          <div class="text-end min-w-max">
            {{ categoryPrefix + "in-stock" | translate | sentenceCase }}
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td>
          <p-tableCheckbox [value]="product"></p-tableCheckbox>
        </td>
        <td>
          <span class="flex gap-3 items-center" title="{{ product.name }}">
            <img
              class="inline-block min-w-[2rem] w-8 h-8 object-cover object-center"
              [src]="getImgUrl(product.avatar?.id)"
              [title]="product?.avatar?.description"
              alt="{{ product?.name }}"
              onerror="this.src='assets/imgs/default/image.webp'"
            />
            <span
              class="max-w-[20rem] md:max-w-[25rem] lg:max-w-[30rem] xl:max-w-[35rem] line-clamp-2"
            >
              {{ product?.name }}
            </span>
          </span>
        </td>
        <td>
          <div class="text-end">
            {{ product?.price_after_tax | currency: " " : "symbol" : "0.0-2" }}
          </div>
        </td>
        <td>
          <div class="text-end">
            {{ product?.quantity }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- <p-paginator
    *ngIf="totalRecords"
    (onPageChange)="onPageChange($event)"
    [first]="first"
    [rows]="rows"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="rowsPerPageOptions"
    dropdownAppendTo="body"
    ngClass="flex justify-end"
  ></p-paginator> -->

  <app-paginator
    [(pagination)]="pagination"
    [totalRecords]="totalRecords"
    [dataLength]="productStockData.length"
    (onChange)="onPageChange($event)"
    stylesClass="flex justify-end"
    [isCompact]="true"
  ></app-paginator>
</div>

<div
  *ngIf="isPending"
  class="absolute z-10 flex top-0 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50"
>
  <p-progressSpinner
    styleClass="w-[2rem] h-[2rem]"
    strokeWidth="2"
    animationDuration=".5s"
  ></p-progressSpinner>
</div>
