import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, OnInit } from '@angular/core';

@Directive({
  selector: '[appAsterisk]',
})
export class AsteriskDirective implements OnInit {
  constructor(
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this._init();
  }

  _init() {
    const reqAsterisk = this.document.createElement('span');
    reqAsterisk.style.color = '#D92D20';
    reqAsterisk.innerHTML = '*';
    this.el.nativeElement.appendChild(reqAsterisk);
  }
}
