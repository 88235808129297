import { Injectable, inject } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class Toast {
  messageService = inject(MessageService);

  private show(
    type: string,
    title: string = 'Title',
    message: string = 'Message',
    life: number = 2000,
  ) {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: message,
      life: life,
    });
  }

  success(title?: string, message?: string, life: number = 2000) {
    this.show('success', title, message);
  }

  info(title?: string, message?: string, life: number = 2000) {
    this.show('info', title, message);
  }

  warn(title?: string, message?: string, life: number = 2000) {
    this.show('warn', title, message);
  }

  error(title?: string, message?: string, life: number = 2000) {
    this.show('error', title, message);
  }
}
