import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { Component } from '@angular/core';
import {
  LocationService,
  SoctripMapModule,
  SoctripMapService,
} from '@soctrip-common/map';
import {
  CategoryControllerService,
  PlaceControllerService,
  SearchingPlaceControllerService,
} from '@tmtrav/map-location-service-api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  standalone: true,
  imports: [SoctripMapModule],
  providers: [
    SoctripMapService,
    CategoryControllerService,
    PlaceControllerService,
    LocationService,
    SearchingPlaceControllerService,
  ],
})
export class MapComponent {
  constructor(
    private soctripMapService: SoctripMapService,
    private LocationService: SearchingPlaceControllerService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe({
      next: (res: any) => {
        this.lat = Number(res.lat ?? 10.761365731981654);
        this.lng = Number(res.lng ?? 106.70496724878726);
        this.highlight = res?.highlight === "true";
        this.zoom = this.highlight ? 20 : 10;
        if (this.first) {
          this.first = false;
        } else {
          this.goToMarker();
        }
      },
    });
  }

  SoctripIcons = SoctripIcons;
  map: any;
  first = true;
  lat: number = 10.761365731981654;
  lng: number = 106.70496724878726;
  highlight = false;
  zoom = 10;

  goToMarker() {
    if (!this.highlight) return;
    this.soctripMapService.hightLightMarker(
      {
        lat: this.lat,
        lon: this.lng,
      },
      20,
    );
  }

  ngAfterViewInit(): void {
    this.LocationService.searchPlaceWithinDestinationByLatLon(
      this.lat,
      this.lng,
      1000000000000,
      ['restaurant'],
      [],
      0,
      100
    ).subscribe({
      next: (res) => {
        this.soctripMapService.addMarkers(res?.data?.data ?? []);
      },
    });
    this.map = this.soctripMapService.getMap();

    this.map.on('click', (e: any) => {
      const event = new CustomEvent('emit-lat-lng', { detail: e.latlng })
      window.parent.document.dispatchEvent(event);
    });
  }
}
