<div class="h-full flex flex-col">
  <header class="py-4 pt-0 flex gap-6 justify-between items-center">
    <label class="font-semibold text-[1.25rem] leading-[1.875rem]">
      {{ sidebarPrefix + "product-categories" | translate | titlecase }}
    </label>
    <button
      class="btn btn-md btn-primary min-w-max"
      (click)="handleAddRootCategory()"
    >
      {{ categoryPrefix + "add-category" | translate | sentenceCase }}
    </button>
  </header>

  <div class="relative flex-grow">
    <ng-container *ngIf="categoriesData?.length; else categoriesNotFound">
      <div class="product-category-container grid grid-flow-col grid-cols-4">
        <!-- Product category list -->
        <app-pro-cat-list
          class="p-1 border-r border-gray-200"
          [(selectedNode)]="selectedNode"
          [categoriesData]="categoriesData"
          (onSortCategory)="onSortCategory($event)"
          (nodeAction)="handleNodeAction($event)"
        ></app-pro-cat-list>

        <!-- Product category details -->
        <app-pro-cat-details
          [categoryNode]="selectedNode"
          class="col-span-3 p-4"
        ></app-pro-cat-details>
      </div>
    </ng-container>

    <ng-template #categoriesNotFound>
      <div class="bg-white h-full flex justify-center gap-6 rounded-lg py-10">
        <div class="w-2/5 grid place-content-center gap-4">
          <div class="flex justify-center">
            <img
              src="/assets/imgs/box-category.webp"
              [alt]="
                textDescription + 'data-not-found' | translate | sentenceCase
              "
            />
          </div>
          <div class="text-gray-900 font-semibold text-center">
            {{
              categoryPrefix + "no-category-found" | translate | sentenceCase
            }}
          </div>
          <div class="text-center">
            {{
              categoryPrefix + "text-description.category-found-description"
                | translate
                | sentenceCase
            }}
          </div>
          <div class="flex justify-center gap-4 text-center">
            <button
              class="btn btn-md btn-primary min-w-max"
              (click)="handleAddRootCategory()"
            >
              {{ categoryPrefix + "add-category" | translate | sentenceCase }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Modal layer for preventing further action -->
    <div
      *ngIf="isPending"
      class="absolute z-10 flex top-0 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50"
    >
      <p-progressSpinner
        styleClass="w-[2rem] h-[2rem]"
        strokeWidth="2"
        animationDuration=".5s"
      ></p-progressSpinner>
    </div>
  </div>
</div>

<!-- Add category dialog -->
<p-dialog
  class="add-dialog"
  header="Header"
  [(visible)]="isCategoryDialogVisible"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '56vw', 'min-width': '960px', 'max-width': '1080px' }"
  [modal]="true"
  [draggable]="false"
  (onHide)="cancelDialog()"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">{{ titleDialog }}</span>
  </ng-template>

  <app-add-category
    [selectedNode]="commandData.key === 'add' ? commandData.node : editNode"
    [categoryGlobalData]="categoryGlobalData"
  >
    <app-upload-image
      [(file)]="imageFile"
      [(url)]="imageUrl"
      (removeImage)="removeEditNodeImage()"
      class="inline-block aspect-square w-1/4"
    ></app-upload-image>
  </app-add-category>

  <ng-template pTemplate="footer">
    <button
      (click)="cancelDialog()"
      class="btn btn-md btn-secondary-gray min-w-[5rem]"
    >
      {{ actionPrefix + "cancel" | translate | sentenceCase }}
    </button>
    <button
      (click)="performDialogAction()"
      class="btn btn-md btn-primary min-w-[5rem]"
      [disabled]="
        !checkDialogValid(
          commandData.key === 'edit' ? editNode : commandData.node
        )
      "
    >
      {{ actionPrefix + commandDialog | translate | titlecase }}
    </button>
  </ng-template>

  <div
    *ngIf="isPending"
    class="absolute z-10 flex top-0 right-0 bottom-0 left-0 items-center justify-center bg-gray-50/50"
  ></div>
</p-dialog>
<!-- End add category Dialog -->

<!-- Confirm dialog -->
<p-confirmDialog
  #cd
  [style]="{ width: '40vw' }"
  [breakpoints]="{ '960px': '75vw' }"
>
  <ng-template pTemplate="footer">
    <button
      class="btn btn-md btn-secondary-gray min-w-[5rem]"
      (click)="cd.reject()"
    >
      {{ actionPrefix + "cancel" | translate | sentenceCase }}
    </button>
    <button
      class="btn btn-md btn-destructive-primary min-w-[5rem]"
      (click)="cd.accept()"
    >
      {{ actionPrefix + "delete" | translate | sentenceCase }}
    </button>
  </ng-template>
</p-confirmDialog>
<!-- End confirm dialog -->
