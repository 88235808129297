import { AfterViewInit, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-shipment-print',
  templateUrl: './order-shipment-print.component.html',
  styleUrls: ['./order-shipment-print.component.scss'],
})
export class OrderShipMentComponent implements AfterViewInit {
  imageSrc = "";

  route = inject(ActivatedRoute);
  constructor() {
    this.imageSrc = environment.GOSHIP_PRINTDOMAIN + this.route.snapshot.paramMap.get('code') || 'demo';
  }
  ngAfterViewInit(): void {
    this.printImage();
  }

  public printImage(): void {
    const printWindow = window.open('', '', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Image</title>
            <style>
              @media print {
                @page { margin: 0; }
                body { margin: 0; padding: 0; }
                img { width: 100vw; height: 100vh; object-fit: contain; }
              }
              @media screen {
                body { text-align: center; margin: 0; }
                img { max-width: 100%; max-height: 100vh; }
              }
            </style>
          </head>
          <body>
            <img src="${this.imageSrc}" />
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = function() {
                  window.close();
                };
              };
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  }
}
