import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';

@Injectable({
  providedIn: 'root',
})
export class GuidelineService {
  constructor(private guideTour: ShepherdService) {}
  defaultStepOptions = {
    // cancelIcon: {
    //   enabled: true,
    // },
    canClickTarget: true,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 4,
  };

  onInit() {
    this.guideTour.defaultStepOptions = this.defaultStepOptions;
  }
}
