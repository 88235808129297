import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { Category } from './models/category';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ShopCatalogControllerService, GlobalCatalogControllerService } from '@soctrip/angular-catalog-service';
import { ErrorHandler } from 'src/app/core/utils/error-handler.util';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { TabGuidelineService } from '../guideline/services/tab-guideline.service';
import { TabStepEnum } from '../guideline/enum/tab-step.enum';
import { FileService } from 'src/app/core/services/file.service';
import { Attachment } from 'src/app/core/models/interfaces/attachment';
import { delay } from 'rxjs';
import { Toast } from 'src/app/core/utils/toast.util';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss'],
})
export class ProductCategoriesComponent implements OnInit {
  shopId: string = '';
  toast = inject(Toast);
  route = inject(ActivatedRoute);
  shopCatalogService = inject(ShopCatalogControllerService);
  globalCatalogService = inject(GlobalCatalogControllerService);
  confirmationService = inject(ConfirmationService);
  // errorHandler = inject(ErrorHandler);
  translator = inject(CustomTranslateService);
  tabGuidelineService = inject(TabGuidelineService);
  fileService = inject(FileService);

  categoryPrefix = 'section-product-n-food-category.';
  actionPrefix = 'section-action.';
  sidebarPrefix = 'section-sidebar.';
  categoryDes = 'section-product-n-food-category.text-description.';
  requiredPrefix = 'section-required.';
  textDescription = 'text-description.';

  categoriesData: Category[] = [];
  categoryGlobalData: any;
  isPending = false;

  selectedNode: any;
  editNode: any; // use this variable to update the desired category

  isCategoryDialogVisible = false;
  commandData: any = { key: '', node: {}, list: [] }; // the variable has 'node' property, to be used when we need to add a new category
  commandDialog = this.translator.sentenceCase(`${this.actionPrefix}.add`);
  titleDialog = this.translator.sentenceCase(`${this.categoryPrefix}add-category`);

  imageFile?: File;
  imageUrl?: string;

  constructor(
    private errorHandler: ErrorHandler,
    private appService: AppService,
  ) {
    this.errorHandler = new ErrorHandler(this.toast);
  }

  ngOnInit(): void {
    this.appService.getShopData().subscribe((data) => {
      if (data?.id) {
        this.shopId = data.id;
        this.getCategoriesListData();
        this.getGlobalCategoryData();
      }
    });
  }

  getCategoriesListData() {
    this.isPending = true;
    this.shopCatalogService.shopCatalogsMyShopObjectIdGet(this.shopId, 0, 2000).subscribe({
      next: (response: any) => {
        const { data, error, success } = response;
        if (success) {
          const categoriesData: Category[] = this.toNestedCategory(data.data);
          this.categoriesData = categoriesData.sort((a, b) => a.order - b.order);
          this.handleIdRoute();
        } else this.handleError(error);
      },
      error: (error: any) => {
        this.handleError(error);
        this.isPending = false;
      },
      complete: () => (this.isPending = false),
    });
  }

  /**
   * Get the global categories, which every category has its own
   */
  getGlobalCategoryData() {
    this.globalCatalogService.globalCatalogsTreeGet().subscribe({
      next: (response: any) => {
        const { data, error, success } = response;
        if (success) this.categoryGlobalData = data;
        else this.handleError(error);
      },
      error: (error: any) => {
        this.handleError(error);
      },
    });
  }

  /**
   * Open a category details if it is included in the route parameters, happens after the category data has been fetched
   */
  handleIdRoute() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      if (id) this.openTreeNode(id);
    });
  }

  openTreeNode(id: any) {
    if (!id) return;
    const node = this.findTreeNode(id as string);
    if (node?.children?.length) node.expanded = true;
    if (node) {
      let parentNode = node.parent;
      while (parentNode) {
        parentNode.expanded = true;
        parentNode = parentNode.parent;
      }
      this.selectedNode = node;
    }
  }

  /**
   * Create a temporary node (root node in this case) for inserting into the tree
   */
  handleAddRootCategory() {
    this.commandData = { key: 'add', node: { name: '', shop_tax: 0 } };
    this.handleNodeAction(this.commandData);
  }

  findTreeNode(id: string, list: any[] = this.categoriesData) {
    let result = list.find((node: any) => node.id === id);
    if (!result) {
      for (const item of list)
        if (item.children?.length) {
          result = this.findTreeNode(id, item.children);
          if (result) break;
        }
    }
    return result;
  }

  findListHasNodeId(id: string, list: any[] = this.categoriesData): any[] | undefined {
    let result = list.find((node: any) => node.id === id) ? list : undefined;
    if (!result) {
      for (const item of list)
        if (item.children?.length) {
          result = this.findListHasNodeId(id, item.children);
          if (result) break;
        }
    }
    return result;
  }

  handleNodeAction(commandData: any) {
    this.commandData = commandData;
    const { key, node } = commandData;
    this.commandDialog = key === 'edit' ? 'save' : key;

    let title: string = '';

    switch (key) {
      case 'add':
        this.commandData.node = {
          name: '',
          image: undefined,
          parent_id: node.id, // if this is the root node, then parent_id will be undefined
          global_id: undefined,
          shop_tax: 0,
        };

        title = this.commandData.node?.parent_id
          ? `${this.categoryPrefix}add-sub-category`
          : `${this.categoryPrefix}add-category`;
        this.titleDialog = this.translator.sentenceCase(title);
        this.imageUrl = '';
        this.showCategoryDialog();
        break;
      case 'edit':
        title = `${this.categoryPrefix}edit-category`;
        this.titleDialog = this.translator.sentenceCase(title);
        this.editNode = { ...node };

        // Patch image url property to the temp variable
        this.imageUrl = this.editNode?.image?.id ? this.fileService.getImgWebp(this.editNode.image.id) : '';

        this.showCategoryDialog();
        break;
      case 'delete':
        this.confirmationService.confirm({
          message: this.translator.sentenceCase(`${this.categoryDes}delete-category-confirmation`),
          header: this.translator.sentenceCase(`${this.categoryDes}delete-confirmation`),
          accept: () => {
            this.handleDeleteCategory(node.id);
          },
          reject: () => {},
        });
        break;
    }
  }

  performDialogAction() {
    const { key, node } = this.commandData;

    switch (key) {
      case 'add':
        if (!this.checkDialogValid(node)) return;
        break;
      case 'edit':
        if (!this.checkDialogValid(this.editNode)) return;
        break;
    }

    this.isPending = true;

    // Check whether the category has the image or not
    if (this.imageFile) {
      this.fileService.uploadFile(this.imageFile).subscribe({
        next: ({ data, error, success }: any) => {
          if (success) {
            this.handleDialogAction(data);
          } else this.handleError(error);
        },
        error: (error: any) => this.handleError(error),
      });
    } else {
      this.handleDialogAction();
    }
  }

  removeEditNodeImage() {
    // If the node is in editing mode, the image itself will be removed
    if (this.commandData.key === 'edit') this.editNode.image = undefined;
  }
  checkShopTax(node: Category) {
    if (node?.shop_tax === null) {
      return false;
    }

    if (node?.shop_tax > 100 || node?.shop_tax < 0) {
      return false;
    }
    if (node?.shop_tax === 0) {
      return true;
    }
    return true;
  }
  checkDialogValid(node: Category) {
    return node?.name && this.checkShopTax(node); // Name required
  }

  handleDialogAction(imageFile?: Attachment) {
    const { key, node } = this.commandData;
    switch (key) {
      case 'add':
        if (this.checkDialogValid(node)) this.handleAddCategory(node, imageFile);
        break;
      case 'edit':
        if (this.checkDialogValid(this.editNode)) this.handleEditCategory(this.editNode.id, this.editNode, imageFile);
        break;
    }
  }

  handleAddCategory(category: Category, imageData?: Attachment) {
    if (imageData) category.image = imageData;

    this.isCategoryDialogVisible = false;
    this.shopCatalogService
      .shopCatalogsObjectIdPost(category, this.shopId)
      .pipe(delay(250))
      .subscribe({
        next: (response: any) => {
          const { data, error, success } = response;
          if (success) {
            const node = data as Category;

            // If the added node has a parent, then the parent node will expand after adding
            const parentNode = this.findTreeNode(data.parent_id);
            if (parentNode) {
              parentNode.children?.length ? parentNode.children.push(node) : (parentNode.children = [node]);
              parentNode.expanded = true;
            } else {
              this.categoriesData.push(node);
            }
            const title = `common.completed`;
            const message = `${this.categoryDes}added-category`;
            this.toast.success(this.translator.sentenceCase(title), this.translator.sentenceCase(message));
            this.getCategoriesListData();
          } else this.handleError(error);

          this.tabGuidelineService.onFinishStep(TabStepEnum.CATEGORY);
        },
        error: (error: any) => this.handleError(error),
        complete: () => {
          this.isPending = false;
          this.imageFile = undefined;
          this.imageUrl = '';
        },
      });
  }

  handleEditCategory(id: string, category: Category, imageData?: Attachment) {
    const data = {
      name: category.name,
      global_id: category.global_id,
      is_used: category.is_used,
      image: imageData || category.image,
      shop_tax: category.shop_tax,
    };

    this.isCategoryDialogVisible = false;
    this.shopCatalogService
      .shopCatalogsObjectIdIdPut(data, this.shopId, id)
      .pipe(delay(250))
      .subscribe({
        next: (response: any) => {
          const { data, error, success } = response;
          if (success) {
            let list = this.findListHasNodeId(id) as TreeNode[];
            const node = this.findTreeNode(id);
            const newNode = { ...node, ...data };
            list.splice(list?.indexOf(node), 1, newNode); // Replace the old node with the new one
            this.selectedNode = this.selectedNode?.id === newNode.id ? newNode : this.selectedNode;

            const title = `common.completed`;
            const message = `${this.categoryDes}updated-category`;
            this.toast.success(this.translator.sentenceCase(title), this.translator.sentenceCase(message));
            this.getCategoriesListData();
          } else this.handleError(error);
        },
        error: (error: any) => this.handleError(error),
        complete: () => {
          this.isPending = false;
          this.imageFile = undefined;
          this.imageUrl = '';
        },
      });
  }

  handleDeleteCategory(id: string) {
    this.shopCatalogService.shopCatalogsObjectIdIdDelete(this.shopId, id).subscribe({
      next: (response: any) => {
        const { data, error, success } = response;
        if (success) {
          let list = this.findListHasNodeId(id) as TreeNode[];
          const node = this.findTreeNode(id);
          list = [...list.splice(list?.indexOf(node), 1)]; // Remove the node from the list

          const title = `common.completed`;
          const message = `${this.categoryDes}deleted-category`;
          this.toast.success(this.translator.sentenceCase(title), this.translator.sentenceCase(message));

          this.selectedNode = this.selectedNode?.id === id ? undefined : this.selectedNode;
          this.getCategoriesListData();
        } else this.handleError(error);
      },
      error: (error: any) => this.handleError(error),
    });
  }

  showCategoryDialog() {
    this.isCategoryDialogVisible = true;
  }

  /**
   *
   * @param flatArrayList the data received in the flat array form
   * @returns the nested array with parent node and children nodes
   */
  toNestedCategory(flatArrayList: Category[]) {
    const map = new Map();
    const result: any = [];

    flatArrayList.forEach((item: any) => {
      delete item?.type;
      map.set(item.id, { ...item, children: [], parent: undefined });
    });

    flatArrayList.forEach((item: any) => {
      const parent = map.get(item.parent_id);
      if (parent) {
        map.get(item.id).parent = parent;
        parent.children.push(map.get(item.id));
      } else {
        result.push(map.get(item.id));
      }
    });

    return result;
  }

  cancelDialog() {
    let { key, node } = this.commandData;
    if (key === 'edit') {
      this.editNode = this.findTreeNode(node.id);
    }
    this.isCategoryDialogVisible = false;
    this.imageFile = undefined;
    this.imageUrl = '';
  }

  handleError(error: any) {
    this.errorHandler.handle(error);
    this.isCategoryDialogVisible = false;
    this.isPending = false;
  }

  onSortCategory(categoryIds: string[]) {
    this.isPending = true;
    this.shopCatalogService.shopCatalogsMyShopShopIdPositionPut(categoryIds, this.shopId).subscribe({
      next: (res) => {
        this.isPending = false;
      },
      error: () => {
        this.isPending = false;
      },
    });
  }
}
