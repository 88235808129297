import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { ProductFormType } from '../pages/product-edit/product-edit.model';

@Directive({
  selector: '[optionValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: OptionValidatorDirective,
      multi: true,
    },
  ],
})
export class OptionValidatorDirective {
  @Input() productForm: ProductFormType;
  @Input() variantIdx: number;
  @Input() optionIdx: number; // The index of the option being validated

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const options =
      this.variantIdx == 0
        ? this.productForm.pricings.map((pricing) => pricing.value)
        : this.productForm.pricings[0].options.map((option) => option.value);

    // Remove the current option being validated from the options array
    const filteredOptions = options.filter((_, idx) => idx !== this.optionIdx);

    // Check for duplicate values in the filtered options
    if (value && filteredOptions.includes(value) && control.dirty) {
      return { duplicated: true };
    }
    return null;
  }
}
