import { Injectable, inject } from '@angular/core';
import { Toast } from './toast.util';
import { from } from 'rxjs';
import { CustomTranslateService } from '../services/custom-translate.service';
@Injectable({
  providedIn: 'root',
})
export class Clipboard {
  clipboard = navigator.clipboard;
  translator = inject(CustomTranslateService);

  constructor(private toast: Toast) {}

  copyText(value: string) {
    const writePromise = from(this.clipboard.writeText(value));
    writePromise.subscribe({
      next: () => {
        const header = this.translator.sentenceCase('common.completed');
        const message = this.translator.sentenceCase(
          'text-description.text-copied-message',
        );
        this.toast.success(header, message);
      },
      error: () => {
        const header = this.translator.sentenceCase(
          'text-description.copy-failed',
        );
        const message = this.translator.sentenceCase(
          'text-description.please-try-again',
        );
        this.toast.error(header, message);
      },
    });
  }
}
