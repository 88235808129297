import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCategoriesRoutingModule } from './product-categories-routing.module';
import { ProductCategoriesComponent } from './product-categories.component';
import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { CategoryDetailsComponent } from './components/category-details/category-details.component';
import { AddCategoryComponent } from './components/add-category/add-category.component';
import { AddProductComponent } from './components/add-product/add-product.component';
import { TreeModule } from 'primeng/tree';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { StockProductControllerService } from './service/stock-product.service';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { OrderListModule } from 'primeng/orderlist';
import { FormsModule } from '@angular/forms';
import { ShopCatalogControllerService, GlobalCatalogControllerService } from '@soctrip/angular-catalog-service';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ShopControllerService, TabControllerService } from '@soctrip/angular-order-service';
import {
  ShopControllerService as StockShopControllerService,
  CatalogControllerService,
} from '@soctrip/angular-stock-service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { UploadImageComponent } from './components/upload-image/upload-image.component';

@NgModule({
  declarations: [
    ProductCategoriesComponent,
    CategoriesListComponent,
    CategoryDetailsComponent,
    AddCategoryComponent,
    AddProductComponent,
    UploadImageComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    ProductCategoriesRoutingModule,
    TreeModule,
    TableModule,
    InputSwitchModule,
    MenuModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    CascadeSelectModule,
    FormsModule,
    PaginatorModule,
    ProgressSpinnerModule,
    BreadcrumbModule,
    OrderListModule,
  ],
  providers: [
    StockProductControllerService,
    ConfirmationService,
    ShopCatalogControllerService,
    GlobalCatalogControllerService,
    ShopControllerService,
    TabControllerService,
    StockShopControllerService,
    CatalogControllerService,
    CustomTranslateService,
  ],
})
export class ProductCategoriesModule {}
