import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';
import { ProductSummary } from 'src/app/core/models/interfaces/product/product-summary';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-products-related',
  templateUrl: './products-related.component.html',
  styleUrls: ['./products-related.component.scss'],
})
export class ProductsRelatedComponent {
  protected readonly SoctripIcons = SoctripIcons;
  @Input() isViewMode: boolean = false;
  @Input({ required: true }) selectedProducts: ProductSummary[] = [];
  @Input({ required: true }) labelAddProduct = '';
  @Output() selectedProductsChange = new EventEmitter<ProductSummary[]>();
  isModalAddProduct = false;
  selectedProductsLocal: ProductSummary[] = [];
  keyword = '';

  @ViewChild('table') table: Table;
  managementPrefix = 'section-product-n-food-management.';

  constructor(public fileService: FileService) {}

  onDeleteProduct(id: string) {
    if (!this.isViewMode) {
      this.selectedProducts = [...this.selectedProducts].filter((product) => product.id !== id);
      this.selectedProductsChange.emit(this.selectedProducts);
    }
  }

  onDeleteProductLocal() {
    const ids = this.selectedProductsLocal.map((product) => product.id);
    this.selectedProducts = [...this.selectedProducts].filter((product) => !ids.includes(product.id));
    this.selectedProductsChange.emit(this.selectedProducts);
  }

  onChangeSelectedProducts(products: ProductSummary[]) {
    this.selectedProductsChange.emit(products);
  }

  onSearchProducts(e: any) {
    this.table.filterGlobal(e.target.value, 'contains');
  }
}
