import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { Router } from '@angular/router';
import shepherd from 'shepherd.js';

import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { TabStepEnum } from '../enum/tab-step.enum';
import { TabGuidelineEnumId } from '../enum/tab-id.enum';
import { ShopControllerService } from '@soctrip/angular-shop-service';
import { BehaviorSubject, of, switchMap } from 'rxjs';
import { TabPathEnum } from '../enum/tab-path.enum';
import { DecorationIdEnum } from '../enum/decoration-id.enum';

export const stepGuidelineConfig = [
  {
    id: TabGuidelineEnumId.SHOP_INFO,
    step: TabStepEnum.SHOP_INFO,
    path: TabPathEnum.SHOP_INFO,
  },
  {
    id: TabGuidelineEnumId.CATEGORY,
    step: TabStepEnum.CATEGORY,
    path: TabPathEnum.CATEGORY,
  },
  {
    id: TabGuidelineEnumId.PRODUCT,
    step: TabStepEnum.PRODUCT,
    path: TabPathEnum.PRODUCT,
  },
  {
    id: TabGuidelineEnumId.SHOP_BUILDER,
    step: TabStepEnum.SHOP_BUILDER,
    path: TabPathEnum.SHOP_BUILDER,
  },
];

@Injectable({
  providedIn: 'root',
})
export class TabGuidelineService {
  guidelinePrefix = 'section-guideline.';
  stepSubject = new BehaviorSubject<number>(stepGuidelineConfig.length);
  stepDecorationSubject = new BehaviorSubject('');
  step$ = this.stepSubject.asObservable();
  stepDecorate$ = this.stepDecorationSubject.asObservable();

  step = stepGuidelineConfig.length;

  finishSubject = new BehaviorSubject<boolean>(false);
  finish$ = this.finishSubject.asObservable();
  finishInstructions = this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`);
  guidelineShopBuilderPrefix = 'section-guideline.shop-builder.';
  nextStep = this.translator.sentenceCase(`${this.guidelinePrefix}next`);
  backStep = this.translator.sentenceCase(`${this.guidelinePrefix}back`);

  constructor(
    private guideTour: ShepherdService,
    private router: Router,
    private translator: CustomTranslateService,
    private shopService: ShopControllerService,
  ) {}

  getStep() {
    return this.step;
  }

  setStep(step: number) {
    this.step = step;
  }

  isActiveStep(id: TabGuidelineEnumId) {
    const index = stepGuidelineConfig.findIndex((step) => step.id == id);
    if (index == -1 && this.getStep() < stepGuidelineConfig.length) {
      return true;
    } else {
      return this.getStep() < index;
    }
  }

  isCurStep(id: TabGuidelineEnumId) {
    const index = stepGuidelineConfig.findIndex((step) => step.id == id);
    return this.getStep() == index;
  }

  onActionStep(path: TabPathEnum) {
    this.router.navigate([path], {
      relativeTo: this.router.routerState.root.firstChild,
    });
    this.guideTour.hide();
  }

  onSkipStep() {
    this.shopService.shopsStagePut().subscribe({
      next: (res) => {
        this.stepSubject.next(res?.data?.stage ?? stepGuidelineConfig.length);
        this.guideTour.next();
      },
    });
  }

  onFinishStep(step: TabStepEnum) {
    const index = stepGuidelineConfig.findIndex((tab) => tab.step === step);
    const nextStep = stepGuidelineConfig[index + 1];

    if (this.getStep() <= index) {
      this.shopService.shopsStagePut(index + 1).subscribe({
        next: (res) => {
          if (res?.data?.stage) {
            this.stepSubject.next(res?.data?.stage ?? 0);
            if (nextStep?.id) {
              this.guideTour.show(nextStep?.id);
            }
          }
        },
      });
    }
  }

  //SHOP DECORATION
  onDecorateShop() {
    this.router.navigate(['shop-builder'], { relativeTo: this.router.routerState.root.firstChild });
    setTimeout(() => {
      this.guideTour.hide();
      this.guideTour.modal = true;
      this.guideTour.confirmCancel = false;
      this.guideTour.addSteps([
        {
          id: DecorationIdEnum.THEME,
          attachTo: {
            element: `#${DecorationIdEnum.THEME}`,
            on: 'right',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}theme-library`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}theme-description`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.APPLYTHEMEORCANCELPREVIEW,
          attachTo: {
            element: `#${DecorationIdEnum.APPLYTHEMEORCANCELPREVIEW}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}apply-or-cancelPreview-for-theme`),
          text: this.translator.sentenceCase(
            `${this.guidelineShopBuilderPrefix}apply-or-cancelPreview-for-description`,
          ),
          classes: 'mt-4',
        },
      ] as unknown as shepherd.Step.StepOptions[]);
      this.guideTour.start();
    }, 200);
  }

  onDecorateShopCancelPreview() {
    setTimeout(() => {
      this.guideTour.hide();
      this.guideTour.modal = true;
      this.guideTour.confirmCancel = false;
      this.guideTour.addSteps([
        {
          id: DecorationIdEnum.EDIT,
          attachTo: {
            element: `#${DecorationIdEnum.EDIT}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}edit-shop`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}edit`),
          classes: 'mt-4',
        },
        {
          id: DecorationIdEnum.UPLOADCOVER,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADCOVER}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-cover-title`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-cover`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.UPLOADAVATAR,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADAVATAR}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-avatar-title`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-avatar`),
          classes: 'mr-4',
        },
        // {
        //   id: DecorationIdEnum.ENTERSHOPNAME,
        //   attachTo: {
        //     element: `#${DecorationIdEnum.ENTERSHOPNAME}`,
        //     on: 'left',
        //   },
        //   buttons: [
        //     {
        //       classes: 'shepherd-button-secondary',
        //       text: this.finishInstructions,
        //       action: () => {
        //         this.onFinishGuideline();
        //       },
        //     },
        //     {
        //       classes: 'shepherd-button-secondary',
        //       text: this.backStep,
        //       type: 'back',
        //     },
        //     {
        //       classes: 'shepherd-button-primary',
        //       text: this.nextStep,
        //       type: 'next',
        //     },
        //   ],
        //   highlightClass: 'highlight',
        //   title: this.translator.sentenceCase(
        //     `${this.guidelineShopBuilderPrefix}enter-shop-name`,
        //   ),
        //   text: this.translator.sentenceCase(
        //     `${this.guidelineShopBuilderPrefix}shop-name`,
        //   ),
        //   classes: 'mr-4',
        // },
        {
          id: DecorationIdEnum.ENTERDESCRIPTION,
          attachTo: {
            element: `#${DecorationIdEnum.ENTERDESCRIPTION}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.DESCRIPTION);
                this.guideTour.next();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}enter-description`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}description`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.NEWTAB,
          attachTo: {
            element: `#${DecorationIdEnum.NEWTAB}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}choose-dropdown`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.OPENDROPDOWN);
                this.guideTour.hide();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}create-new-tab-title`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}create-new-tab`),
          classes: 'mt-4',
        },
        {
          id: DecorationIdEnum.CLICKTABNAME,
          attachTo: {
            element: `#${DecorationIdEnum.CLICKTABNAME}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}new-tab-name`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}click-tab-name`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.DRAGANDROPBLOCK,
          attachTo: {
            element: `#${DecorationIdEnum.DRAGANDROPBLOCK}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}position-drag-drop`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}drag-and-drop-block`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.DRAGANDROPBLOCKELEMENT,
          attachTo: {
            element: `#${DecorationIdEnum.DRAGANDROPBLOCKELEMENT}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}drag-and-drop`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.OPENMENU);
                this.guideTour.hide();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}decorative-block`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}drag-and-drop-block-element`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.UPLOADIMAGE,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADIMAGE}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.UPLOADBANNER);
                this.guideTour.next();
              },
            },
          ],
          scrollTo: true,
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-image-title`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-image`),
          classes: 'mr-16',
        },
        {
          id: DecorationIdEnum.SAVE,
          attachTo: {
            element: `#${DecorationIdEnum.SAVE}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}save-shop`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}save`),
          classes: 'mt-4',
        },
      ] as unknown as shepherd.Step.StepOptions[]);
      this.guideTour.start();
    });
  }

  onDecorateShopApply() {
    setTimeout(() => {
      this.guideTour.hide();
      this.guideTour.modal = true;
      this.guideTour.confirmCancel = false;
      this.guideTour.addSteps([
        {
          id: DecorationIdEnum.UPLOADCOVER,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADCOVER}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-cover-title`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-cover`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.UPLOADAVATAR,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADAVATAR}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-avatar-title`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-avatar`),
          classes: 'mr-4',
        },
        // {
        //   id: DecorationIdEnum.ENTERSHOPNAME,
        //   attachTo: {
        //     element: `#${DecorationIdEnum.ENTERSHOPNAME}`,
        //     on: 'left',
        //   },
        //   buttons: [
        //     {
        //       classes: 'shepherd-button-secondary',
        //       text: this.finishInstructions,
        //       action: () => {
        //         this.onFinishGuideline();
        //       },
        //     },
        //     {
        //       classes: 'shepherd-button-secondary',
        //       text: this.backStep,
        //       type: 'back',
        //     },
        //     {
        //       classes: 'shepherd-button-primary',
        //       text: this.nextStep,
        //       type: 'next',
        //     },
        //   ],
        //   highlightClass: 'highlight',
        //   title: this.translator.sentenceCase(
        //     `${this.guidelineShopBuilderPrefix}enter-shop-name`,
        //   ),
        //   text: this.translator.sentenceCase(
        //     `${this.guidelineShopBuilderPrefix}shop-name`,
        //   ),
        //   classes: 'mr-4',
        // },
        {
          id: DecorationIdEnum.ENTERDESCRIPTION,
          attachTo: {
            element: `#${DecorationIdEnum.ENTERDESCRIPTION}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.DESCRIPTION);
                this.guideTour.next();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}enter-description`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}description`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.NEWTAB,
          attachTo: {
            element: `#${DecorationIdEnum.NEWTAB}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}choose-dropdown`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.OPENDROPDOWN);
                this.guideTour.hide();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}create-new-tab-title`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}create-new-tab`),
          classes: 'mt-4',
        },
        {
          id: DecorationIdEnum.CLICKTABNAME,
          attachTo: {
            element: `#${DecorationIdEnum.CLICKTABNAME}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}new-tab-name`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}click-tab-name`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.DRAGANDROPBLOCK,
          attachTo: {
            element: `#${DecorationIdEnum.DRAGANDROPBLOCK}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}position-drag-drop`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}drag-and-drop-block`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.DRAGANDROPBLOCKELEMENT,
          attachTo: {
            element: `#${DecorationIdEnum.DRAGANDROPBLOCKELEMENT}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}drag-and-drop`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.OPENMENU);
                this.guideTour.hide();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}decorative-block`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}drag-and-drop-block-element`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.UPLOADIMAGE,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADIMAGE}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.backStep,
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.nextStep,
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.UPLOADBANNER);
                this.guideTour.next();
              },
            },
          ],
          scrollTo: true,
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-image-title`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}upload-image`),
          classes: 'mr-16',
        },
        {
          id: DecorationIdEnum.SAVE,
          attachTo: {
            element: `#${DecorationIdEnum.SAVE}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.finishInstructions,
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}save-shop`),
          text: this.translator.sentenceCase(`${this.guidelineShopBuilderPrefix}save`),
          classes: 'mt-4',
        },
      ] as unknown as shepherd.Step.StepOptions[]);
      this.guideTour.start();
    });
  }

  onDecorateFinish(isShowToast: boolean) {
    this.shopService
      .shopsStatusGet()
      .pipe(
        switchMap((res) => {
          if (res?.data?.stage && res?.data?.stage < stepGuidelineConfig.length) {
            return this.shopService.shopsStagePut(stepGuidelineConfig.length);
          } else {
            return of(undefined);
          }
        }),
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.stage) {
            this.stepSubject.next(res?.data?.stage ?? 0);
            if (isShowToast) {
              this.finishSubject.next(true);
            }
            this.guideTour.complete();
          }
        },
      });
  }

  onFinishGuideline() {
    this.shopService
      .shopsStatusGet()
      .pipe(
        switchMap((res) => {
          if (res?.data?.stage && res?.data?.stage < stepGuidelineConfig.length) {
            return this.shopService.shopsStagePut(stepGuidelineConfig.length);
          } else {
            return of(undefined);
          }
        }),
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.stage) {
            this.stepSubject.next(res?.data?.stage ?? 0);
            this.finishSubject.next(true);
            this.guideTour.complete();
          }
        },
      });
  }

  onInit(curIndex: number, code: string) {
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;

    const infoShopStep = {
      id: TabGuidelineEnumId.SHOP_INFO,
      step: TabStepEnum.SHOP_INFO,
      attachTo: {
        element: `#${TabGuidelineEnumId.SHOP_INFO}`,
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'skip-instructions'),
          action: () => {
            this.onFinishGuideline();
          },
        },
        {
          classes: 'shepherd-button-outline',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'next'),
          action: () => {
            this.onSkipStep();
          },
        },
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'enter'),
          action: () => {
            this.onActionStep(TabPathEnum.SHOP_INFO);
          },
        },
      ],
      highlightClass: 'highlight',
      title: this.translator.sentenceCase(this.guidelinePrefix + 'shop-information'),
      text: this.translator.sentenceCase(this.guidelinePrefix + 'content-shop-information'),
      classes: 'mt-4',
    };
    const categoryStep = {
      id: TabGuidelineEnumId.CATEGORY,
      step: TabStepEnum.CATEGORY,
      attachTo: {
        element: `#${TabGuidelineEnumId.CATEGORY}`,
        on: 'right',
      },
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'skip-instructions'),
          action: () => {
            this.onFinishGuideline();
          },
        },
        {
          classes: 'shepherd-button-outline',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'next'),
          action: () => {
            this.onSkipStep();
          },
        },
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'create-category'),
          action: () => {
            this.onActionStep(TabPathEnum.CATEGORY);
          },
        },
      ],
      highlightClass: 'highlight',
      title: this.translator.sentenceCase(this.guidelinePrefix + 'create-category'),
      text: this.translator.sentenceCase(this.guidelinePrefix + 'content-create-category'),
      classes: 'ml-4',
    };
    const productStep = {
      id: TabGuidelineEnumId.PRODUCT,
      step: TabStepEnum.PRODUCT,
      attachTo: {
        element: `#${TabGuidelineEnumId.PRODUCT}`,
        on: 'right',
      },
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'skip-instructions'),
          action: () => {
            this.onFinishGuideline();
          },
        },
        {
          classes: 'shepherd-button-outline',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'next'),
          action: () => {
            this.onSkipStep();
          },
        },
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'create-product'),
          action: () => {
            this.onActionStep(TabPathEnum.PRODUCT);
          },
        },
      ],
      highlightClass: 'highlight',
      title: this.translator.sentenceCase(this.guidelinePrefix + 'create-product'),
      text: this.translator.sentenceCase(this.guidelinePrefix + 'content-create-product'),
      classes: 'ml-4',
    };
    const shopBuilderStep = {
      id: TabGuidelineEnumId.SHOP_BUILDER,
      step: TabStepEnum.SHOP_BUILDER,
      attachTo: {
        element: `#${TabGuidelineEnumId.SHOP_BUILDER}`,
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'finish'),
          action: () => {
            this.onFinishGuideline();
          },
        },
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'decoration'),
          action: () => {
            this.onDecorateShop();
          },
        },
      ],
      highlightClass: 'highlight',
      title: this.translator.sentenceCase(this.guidelinePrefix + 'decoration'),
      text: this.translator.sentenceCase(this.guidelinePrefix + 'content-decoration'),
      classes: 'mt-4 ml-2',
    };

    if (code === 'NEW') {
      infoShopStep.buttons.pop();
    }

    const steps = [infoShopStep, categoryStep, productStep, shopBuilderStep];

    const stepConfig: shepherd.Step.StepOptions[] = [];

    stepGuidelineConfig.forEach((order) => {
      const step = steps.find((item) => item.id == order.id);
      if (step) {
        stepConfig.push(step as shepherd.Step.StepOptions);
      }
    });

    this.guideTour.addSteps(stepConfig.slice(curIndex));
    this.guideTour.start();
  }
}
