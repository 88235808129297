export enum LivestreamTabEnum {
  ALL = 'ALL',
  ONGOING = 'ONGOING',
  UPCOMING = 'UPCOMING',
  ENDED = 'ENDED',
  COMPLETED = 'COMPLETED',
}

export enum LivestreamPageEnum {
  CREATE = 'add',
  VIEW = 'view',
  UPDATE = 'update',
}

export enum CampaignActionEnum {
  GO_LIVE = 'GO_LIVE',
  VIEW_DETAILS = 'VIEW_DETAILS',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  CLONE = 'CLONE',
  DASHBOARD = 'DASHBOARD',
  SUPPORT = 'SUPPORT',
}

export enum ProductTypeEnum {
  ONGOING = 'On-going',
  UPCOMING = 'Upcoming',
}

export enum ApprovalStatusEnum {
  PENDING = 'WAITING_FOR_APPROVE',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
