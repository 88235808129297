import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appMaxPriceValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxPriceValidatorDirective,
      multi: true,
    },
  ],
})
export class MaxPriceValidatorDirective {
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value && value > Math.pow(10, 11) - 1) {
      return {
        max: true,
      };
    }
    return null;
  }
}
