import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { Product } from '../../models/product';
import { Table } from 'primeng/table';
import { PageEvent } from '../../models/pageEvent';
import { Category } from '../../models/category';
import { FileService } from 'src/app/core/services/file.service';
import { ErrorHandler } from 'src/app/core/utils/error-handler.util';
import { ShopControllerService } from '@soctrip/angular-stock-service';
import { Toast } from 'src/app/core/utils/toast.util';
import { StockProductControllerService } from '../../service/stock-product.service';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnChanges, OnInit {
  @Input() isPending = false;
  @Input() categoryNode!: Category;
  @Input() editData: { addData: any[]; deleteData: any[] } = {
    addData: [],
    deleteData: [],
  };
  @Output() editDataChange = new EventEmitter();
  shopId: string = '';
  toast = inject(Toast);
  fileService = inject(FileService);
  errorHandler = inject(ErrorHandler);
  customStockProductService = inject(StockProductControllerService);

  categoryPrefix = 'section-product-n-food-category.';
  actionPrefix = 'section-action.';
  categoryDes = 'section-product-n-food-category.text-description.';

  selectedProducts: Product[] = [];
  bufferSelectedId: any[] = [];

  private searchTimeout: any;
  productStockData: any[] = [];

  // page: number = 0;
  // first: number = 0;
  // rows: number = 10;
  totalRecords: number;
  rowsPerPageOptions = [5, 10, 15, 20];
  pagination = {
    rows: 10,
    page: 0,
  };
  constructor(private appService: AppService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.appService.getShopData().subscribe((data) => {
      if (data?.id) {
        this.shopId = data.id;
        if (changes['categoryNode']) {
          this.resetData();
          this.getPageStockProduct();
        }
      }
    });
  }

  ngOnInit(): void {
    this.appService.getShopData().subscribe((data) => {
      if (data?.id) {
        this.shopId = data.id;
        this.getPageStockProduct();
      }
    });
  }

  getPageStockProduct(
    pageNum: number = this.pagination.page,
    pageSize: number = this.pagination.rows,
    filters?: string,
    sort?: string,
  ) {
    this.isPending = true;
    this.customStockProductService
      .shopsMyShopCatalogSearchGet(this.shopId, pageNum, pageSize, filters, sort)
      .subscribe({
        next: (response: any) => {
          const { data, error, success } = response;
          if (success) {
            this.productStockData = data.data;
            this.totalRecords = data.totalElement;
            this.checkSelectedIdList();
          } else {
            this.errorHandler.handle(error);
            this.resetData();
          }
        },
        error: (error: any) => {
          this.errorHandler.handle(error);
          this.resetData();
        },
        complete: () => (this.isPending = false),
      });
  }

  getImgUrl(id: string): string {
    return this.fileService.getImgWebp(id);
  }

  /**
   * Check whether the selected items are on the list
   */
  checkSelectedIdList() {
    this.selectedProducts = [];
    let idList = this.bufferSelectedId;
    for (const product of this.productStockData) if (idList.includes(product.id)) this.selectedProducts.push(product);
  }

  handleSelection(event: any) {
    let idList = this.bufferSelectedId;
    for (const product of this.selectedProducts) {
      if (!idList.includes(product.id)) idList.push(product.id);
    }

    for (const product of this.productStockData) {
      if (!this.selectedProducts.includes(product) && idList.includes(product.id))
        idList.splice(idList?.indexOf(product.id), 1);
    }

    this.editData.addData = [...idList];
    this.editDataChange.emit(this.editData);
  }

  onSearchInputChange(value: string) {
    clearTimeout(this.searchTimeout);
    const searchURI = value ? `name@=${value}` : '';
    this.searchTimeout = setTimeout(() => {
      this.resetPagination();
      this.getPageStockProduct(this.pagination.page, this.pagination.rows, searchURI);
    }, 300);
  }

  resetPagination() {
    this.pagination.page = 0;
    // this.first = 0;
  }

  onPageChange(event: PageEvent) {
    // if (event.first && event.rows && event.page) {
    //   this.first = event.first;
    //   this.rows = event.rows;
    //   this.page = event.page;
    // }
    // this.getPageStockProduct(event.page, event.rows);
    this.getPageStockProduct();
  }

  resetData() {
    this.pagination.page = 0;
    // this.first = 0;
    // this.rows = 10;
    this.totalRecords = 0;
    this.bufferSelectedId = [];
    this.productStockData = [];
    this.selectedProducts = [];
    this.editData = { addData: [], deleteData: [] };
    this.isPending = false;
  }

  getCurrencyCode() {
    return 'USD';
  }
}
