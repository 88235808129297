import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { ProductGet } from 'src/app/core/models/interfaces/product/product-detail';
import { FileService } from 'src/app/core/services/file.service';
import { environment } from 'src/environments/environment';
import { PricingFormType, ProductFormType } from '../../pages/product-edit/product-edit.model';

@Component({
  selector: 'app-product-pricing',
  templateUrl: './product-pricing.component.html',
  styleUrls: ['./product-pricing.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProductPricingComponent {
  readonly appName = environment.APP_NAME;
  SoctripIcons = SoctripIcons;
  actionPrefix = 'section-action.';
  managementPrefix = 'section-product-n-food-management.';
  descriptionPrefix = 'text-description.';
  uploadingIds: string[] = [];

  @Input({ required: true }) productForm: ProductFormType;
  @Input({ required: true }) productData: ProductGet;
  @Input({ required: true }) attachmentsDisplay: any[] = [];
  @Input({ required: true }) isCreateMode = true;
  @Input() isViewMode: boolean = false;

  @Output() onLoadPricing = new EventEmitter();

  constructor(public fileService: FileService) {}

  handleConvertPricing() {
    const pricings: PricingFormType[] = [];
    const [v1, v2] = this.productForm.variants;

    v1?.values.forEach((valueFirst, firstIdx) => {
      const previousPricing = this.productForm.pricings?.[firstIdx];
      const imageIndex =
        previousPricing?.imageIndex !== undefined
          ? previousPricing.imageIndex
          : this.productData?.attachments.findIndex(
              (att) => att.id === this.productData?.prices?.[0]?.variations[firstIdx]?.image?.id,
            ) ?? -1;
      const pricing: PricingFormType = {
        key: v1.key || '',
        value: valueFirst.data || '',
        imageIndex,
        options: [],
      };

      if (v2) {
        // HAVE 2 VARIANTS
        pricing.options = [...v2.values].map((valueSecond, secondsIdx) => {
          const previousOption = previousPricing?.options[secondsIdx] || {};
          const curPricing = this.productData?.stocks?.find(
            (stock) => stock.order_first === firstIdx + 1 && stock.order_second === secondsIdx + 1,
          );
          return {
            key: v2.key || '',
            value: valueSecond.data || '',
            price: previousOption.price || curPricing?.original_price || 1,
            sale_price: previousOption.sale_price || curPricing?.price || 1,
            in_stock: previousOption.in_stock || curPricing?.quantity || 1,
            sku: previousOption.sku || curPricing?.sku,
            image: previousOption.image || curPricing?.image || undefined,
            s_sku: previousOption.s_sku || curPricing?.s_sku || '',
          };
        });
      } else {
        // HAVE 1 VARIANT
        const previousOption = previousPricing?.options[0] || {};
        const curPricing = this.productData?.stocks?.find((stock) => stock.order_first === firstIdx + 1);
        pricing.options = [
          {
            price: previousOption.price || curPricing?.original_price || 1,
            sale_price: previousOption.sale_price || curPricing?.price || 1,
            in_stock: previousOption.in_stock || curPricing?.quantity || 1,
            sku: previousOption.sku || curPricing?.sku,
            s_sku: previousOption.s_sku || curPricing?.s_sku || '',
            image: previousOption.image || curPricing?.image || undefined,
          },
        ];
      }
      pricings.push(pricing);
    });
    this.productForm.pricings = pricings;

    this.onChangeSKU();
  }

  handleApplyForAllVariants() {
    const { price, sale_price, in_stock } = this.productForm;
    this.productForm.pricings.forEach((pricing, i) => {
      pricing.options.forEach((_, j) => {
        const option = { ...this.productForm.pricings[i].options[j] };
        this.productForm.pricings[i].options[j] = {
          ...option,
          price: price ?? 0,
          sale_price: sale_price ?? 0,
          in_stock: in_stock ?? 0,
        };
      });
    });
  }

  onVariantValueChange(e: string, variantIdx: number, valueIdx: number) {
    this.productForm.variants[variantIdx].values[valueIdx].data = e;
    switch (variantIdx) {
      case 0: {
        this.productForm.pricings[valueIdx].value = e;
        break;
      }
      case 1: {
        this.productForm.pricings.forEach((pricing) => {
          pricing.options[valueIdx].value = e;
        });
      }
    }
    this.onChangeSKU();
  }

  onVariantKeyChange(e: string, variantIdx: number) {
    this.productForm.variants[variantIdx].key = e;
    this.onChangeSKU();
  }

  onDeleteVariant(tableIndex: number, rowIndex: number) {
    const tableOrder = tableIndex + 1;
    const colOrder = rowIndex + 1;

    this.productData?.prices[tableIndex]?.variations.splice(rowIndex, 1);
    this.productForm.variants[tableIndex].values.splice(rowIndex, 1);

    switch (tableOrder) {
      case 1: {
        this.productForm?.pricings.splice(rowIndex, 1);
        if (this.productForm.pricings.length === 0) {
          this.productForm.variants.splice(tableIndex, 1);
          this.handleConvertPricing();
        }
        if (this.productData) {
          const stocks = this.productData?.stocks.filter((stock) => stock.order_first === colOrder);
          this.productData.stocks = [...this.productData?.stocks].filter(
            (stock) => !stocks.map((item) => item.id).includes(stock.id),
          );
          this.productData?.stocks.forEach((stock) => {
            if (stock.order_first > colOrder) {
              stock.order_first--;
            }
          });
        }

        break;
      }
      case 2: {
        this.productForm.pricings.forEach((pricing) => {
          pricing.options.splice(rowIndex, 1);
        });
        if (this.productForm.pricings[0].options.length === 0) {
          this.productForm.variants.splice(tableIndex, 1);
          this.handleConvertPricing();
        }
        if (this.productData) {
          const stocks = this.productData?.stocks.filter((stock) => stock.order_second === colOrder);
          this.productData.stocks = [...this.productData?.stocks].filter((stock) => {
            return !stocks.map((item) => item.id).includes(stock.id);
          });
          this.productData?.stocks.forEach((stock) => {
            if (stock.order_second > colOrder) {
              stock.order_second--;
            }
          });
        }
        break;
      }
    }
  }

  onAddVariant() {
    this.productForm.variants.push({
      key: '',
      values: [{ data: '' }],
    });
    this.handleConvertPricing();
  }

  onAddOption(i: number) {
    this.productForm.variants[i].values.push({
      data: '',
    });
    this.handleConvertPricing();
  }

  onUploadImage(event: Event, rowIndex: number, optionIndex: number) {
    const files = (event.target as HTMLInputElement).files;
    const id = `${rowIndex}-${optionIndex}`;
    this.uploadingIds.push(id);
    if (files) {
      this.fileService.uploadFile(files[0]).subscribe({
        next: (res) => {
          const image = res?.data;
          if (image) {
            this.productForm.pricings[rowIndex].options[optionIndex].image = image;
          }
          this.uploadingIds = [...this.uploadingIds].filter((item) => item != id);
        },
      });
    }
  }

  onChangeSKU() {
    this.productForm.pricings?.forEach((pricing) => {
      pricing.options?.forEach((option) => {
        option.sku = [this.productForm.sku, pricing.value?.replaceAll(' ', '_'), option.value?.replaceAll(' ', '_')]
          .filter((item) => !!item)
          .join('_');
      });
    });
  }

  get isSingleVariant(): boolean {
    return (
      (this.productForm.pricings.length === 1 && this.productForm.variants.length === 1) ||
      (this.productForm.variants.length === 2 &&
        this.productForm.variants[0].values.length === 1 &&
        this.productForm.variants[1].values.length === 1)
    );
  }
}
