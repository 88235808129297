import { Component, Output, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MessageService, PrimeIcons } from 'primeng/api';
import { SeverityLevel } from 'src/app/core/constants/severity.enum';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';
import { AttachmentFormType } from '../../pages/product-edit/product-edit.model';
import { Attachment } from 'src/app/core/models/interfaces/attachment';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';

@Component({
  selector: 'app-product-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss'],
})
export class UploadImagesComponent {
  @Input() attachments: AttachmentFormType[] = [];
  @Input() attachmentsDisplay: AttachmentFormType[] = [];
  @Input() avatarIndex: number = 0;
  @Input() isViewMode: boolean = false;
  @Output() attachmentsChange = new EventEmitter<any[]>();
  @Output() attachmentsDisplayChange = new EventEmitter<any[]>();
  @Output() avatarIndexChange = new EventEmitter<any>();

  protected readonly managementPrefix = 'section-product-n-food-management.';
  protected readonly descriptionPrefix = 'text-description.';
  protected readonly actionPrefix = 'section-action.';
  protected readonly SoctripIcons = SoctripIcons;

  files: FileList;

  attachmentFocus = 0;
  link = '';
  isValidLink = false;
  maxLengthImages = 50

  constructor(
    private cr: ChangeDetectorRef,
    public fileService: FileService,
    private messageService: MessageService,
    private translator: CustomTranslateService,
  ) {}

  ngOnChanges() {
    this.attachmentFocus = this.avatarIndex;
    this.cr.detectChanges();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
  }

  onSelectImage(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.handleUploadImages(files);
    }
  }

  onDeleteImage(index: number) {
    this.attachments = [...this.attachments.slice(0, index), ...this.attachments.slice(index + 1)];
    this.attachmentsDisplay = [...this.attachmentsDisplay.slice(0, index), ...this.attachmentsDisplay.slice(index + 1)];

    this.attachmentsDisplayChange.emit(this.attachmentsDisplay);
    this.attachmentsChange.emit(this.attachments.map((att, index) => ({ ...att, index })));

    if (index <= this.avatarIndex) {
      this.onChangeAvatar(this.avatarIndex - 1 >= 0 ? this.avatarIndex - 1 : 0);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.handleUploadImages(files);
    }
  }

  handleUploadImages(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        var reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (event: ProgressEvent<FileReader>) => {
          if (!event.target) return;

          const type = files[i].type;
          const imageTypes = ['image/svg+xml', 'image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/webp', 'image/tiff', 'image/gif'];
          if (imageTypes.includes(type)) {
            const image = new Image();
            image.src = event.target.result as string;

            image.onload = () => {
              if (this.attachments.length === this.maxLengthImages) {
                this.messageService.add({
                  severity: SeverityLevel.WARN,
                  detail: `Only allowed to post a maximum of ${this.maxLengthImages} images or videos`,
                  icon: PrimeIcons.IMAGE,
                });
                return;
              }
              if (image.width >= 300 && image.width <= 3000 && image.height >= 300 && image.height <= 3000) {
                this.attachmentsDisplay = [
                  ...this.attachmentsDisplay,
                  {
                    type: 'image',
                    data: image.src,
                  },
                ];
                this.attachments = [
                  ...this.attachments,
                  {
                    type: 'image',
                    data: files[i],
                    index: this.attachments.length,
                  },
                ];
                this.attachmentsDisplayChange.emit(this.attachmentsDisplay);
                this.attachmentsChange.emit(this.attachments);
              } else {
                this.messageService.add({
                  severity: SeverityLevel.WARN,
                  detail: this.translator.transform(this.managementPrefix + 'toast.invalid-image'),
                  icon: PrimeIcons.IMAGE,
                });
              }
            };
          } else {
            this.messageService.add({
              severity: SeverityLevel.WARN,
              detail: this.translator.transform(this.managementPrefix + 'toast.unsupport-file'),
              icon: PrimeIcons.IMAGE,
            });
          }
          // else if (type.indexOf('video') > -1) {
          //   this.attachmentsDisplay = [...this.attachmentsDisplay, {
          //     type: "video",
          //     data: event.target.result
          //   }];
          //   this.attachments = [...this.attachments, {
          //     type: "video",
          //     data: files[i]
          //   }];
          //   this.attachmentsDisplayChange.emit(this.attachmentsDisplay);
          //   this.attachmentsChange.emit(this.attachments);
          // }
        };
      }
    }
  }

  onLinkChange(event: string) {
    if (this.fileService.isVideoUrl(event) || this.fileService.isYoutubeUrl(event)) {
      this.isValidLink = true;
    }
  }

  onUploadLink() {
    if (this.fileService.isYoutubeUrl(this.link)) {
      const id = this.fileService.getYoutubeId(this.link);
      this.link = `https://www.youtube.com/embed/${id}`;
    }

    this.attachmentsDisplay = [
      ...this.attachmentsDisplay,
      {
        type: 'link',
        data: {
          type: 'link',
          id: this.link,
        },
      },
    ];
    this.attachments = [
      ...this.attachments,
      {
        type: 'link',
        data: {
          type: 'link',
          id: this.link,
        } as Attachment,
        index: this.attachments.length,
      },
    ];
    this.attachmentsDisplayChange.emit(this.attachmentsDisplay);
    this.attachmentsChange.emit(this.attachments);
    this.isValidLink = false;
    this.link = '';
  }

  onChangeAvatar(index: number) {
    this.avatarIndex = index;
    this.avatarIndexChange.emit(index);
  }

  checkIsImage(type: string) {
    return type.indexOf('image') > -1;
  }

  checkIsVideo(type: string) {
    return type.indexOf('video') > -1;
  }
}
