<p-dialog
  [modal]="true"
  [draggable]="false"
  [style]="{ width: '60vw', 'min-width': '760px', 'max-width': '900px', height: '80vh' }"
  [(visible)]="isModal"
  (onHide)="setIsModal(false)"
  [header]="'common.add-product' | translate | sentenceCase"
>
  <!-- Total product -->
  <div class="text-sm flex justify-start gap-1 -mt-2 mb-4">
    <span> {{ "common.selected" | translate | sentenceCase }}: </span>
    <span class="font-medium"> {{ selectedProducts.length }} /{{ _totalProduct }} </span>
    <span>
      {{ "common.products" | translate }}
    </span>
  </div>
  <div class="flex gap-2">
    <div class="pb-4 w-full flex flex-col gap-2">
      <label class="text-sm">
        {{ "common.categories" | translate | sentenceCase }}
        <span class="text-orange-dark-500 text-sm">*</span>
      </label>
      <p-treeSelect
        [metaKeySelection]="false"
        [(ngModel)]="categories"
        [ngModelOptions]="{ standalone: true }"
        [options]="_categories"
        placeholder="All categories"
        containerStyleClass="w-full"
        selectionMode="multiple"
        display="chip"
        (ngModelChange)="_fetchProducts(true)"
      >
        <ng-template pTemplate="value">
          <div *ngIf="categories.length == 0">All categories</div>
          <div>
            <ul class="flex flex-wrap gap-2">
              <li
                class="flex items-center justify-center px-3 py-1 rounded-full gap-2 bg-gray-200"
                *ngFor="let category of categories; let i = index"
              >
                <span>{{ category.label }}</span>
                <span (click)="onRemoveCategory($event, i)" [class]="SoctripIcons.X_CLOSE"></span>
              </li>
            </ul>
          </div>
        </ng-template>
      </p-treeSelect>
    </div>
  </div>
  <div class="border rounded-lg overflow-hidden relative">
    <div
      *ngIf="isFetching"
      class="absolute z-10 flex top-0 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50"
    ></div>
    <p-table
      dataKey="id"
      scrollHeight="calc(80vh - 350px)"
      [scrollable]="true"
      [selectionPageOnly]="true"
      [selection]="selectedProducts"
      [value]="_products"
      [tableStyle]="{ width: '100%' }"
      (selectionChange)="onChangeSelectedProducts($event)"
      (onHeaderCheckboxToggle)="onSelectAllChange($event)"
    >
      <ng-template pTemplate="caption">
        <div class="w-full flex justify-between gap-6 items-center">
          <h2 class="font-semibold text-lg flex-1">
            {{ "common.product-list" | translate | sentenceCase }}
          </h2>
          <span class="p-input-icon-left w-full flex-1">
            <i class="pi pi-search"></i>
            <input
              [(ngModel)]="keyword"
              (ngModelChange)="keywordChanged.next($event)"
              class="h-[44px] !text-sm w-full"
              type="text"
              pInputText
              [placeholder]="managementPrefix + 'search-product' | translate | sentenceCase"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox [disabled]="disableCheckbox || isViewMode"></p-tableHeaderCheckbox>
          </th>
          <th class="text-sm !font-medium w-[400px]">
            {{ "common.products" | translate | sentenceCase }}
          </th>
          <th class="text-sm !font-medium !text-right">
            {{ "common.price" | translate | sentenceCase }}
          </th>
          <th class="text-sm !font-medium !text-right">
            {{ "common.stock" | translate | sentenceCase }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>
            <p-tableCheckbox
              [disabled]="idsDisable.includes(product.id) || isViewMode || product.is_disable || product.is_ongoing"
              [value]="product"
            ></p-tableCheckbox>
          </td>
          <td class="text-sm !font-medium">
            <div class="flex items-center gap-2">
              <app-custom-image
                class="min-w-[40px] w-10 h-10 overflow-hidden rounded-full"
                [src]="product?.avatar?.id"
                [alt]="'common.product' | translate | sentenceCase"
              >
              </app-custom-image>
              <div class="flex flex-col gap-0.5">
                <span class="line-clamp-2 max-w-[400px]">
                  {{ product.name }}
                </span>
                <small *ngIf="product.is_disable" class="text-xs text-error-500">
                  {{
                    (isUpcomingProduct
                      ? managementPrefix + "already-added-to-on-going"
                      : managementPrefix + "already-added-to-upcoming"
                    ) | translate
                  }}</small
                >
              </div>
            </div>
          </td>
          <td class="text-sm !font-medium !text-right">
            <span class="flex flex-1 items-center justify-end gap-1">
              <span>{{
                product.original_price
                  | currency: currentCurrency : "symbol" : (currentCurrency === "VND" ? "1.0-0" : "1.2-2")
              }}</span>
            </span>
          </td>
          <td class="text-sm !font-medium !text-right">
            {{ product.quantity }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div *ngIf="_products.length === 0 && isFetched" class="bg-white w-full flex flex-col items-center py-4">
          <img
            class="h-[60px]"
            src="assets/imgs/empty-data.svg"
            [alt]="'common.empty-data' | translate | sentenceCase"
          />
          <span class="text-gray-300 mt-2 text-xs">
            {{ "common.no-data" | translate | sentenceCase }}
          </span>
        </div>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <div class="h-[70px]">
      <app-paginator
        (onChange)="_fetchProducts(false)"
        [(pagination)]="pagination"
        [totalRecords]="_totalProduct"
        [dataLength]="_products.length"
        [isCompact]="true"
        stylesClass="!px-4 !pb-0"
      ></app-paginator>
    </div>

    <div [ngClass]="{ 'pt-4': _totalProduct <= 5 }" class="w-full flex justify-end gap-1">
      <app-button
        [label]="'section-action.cancel' | translate | sentenceCase"
        variant="outline"
        (onClick)="onCancelAddProduct()"
      ></app-button>
      <app-button [label]="'section-action.add' | translate | sentenceCase" (onClick)="onAddProducts()"></app-button>
    </div>
  </ng-template>
</p-dialog>
