import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ProductFormType } from '../pages/product-edit/product-edit.model';

@Directive({
  selector: '[skuValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SkuValidatorDirective,
      multi: true,
    },
  ],
})
export class SkuValidatorDirective implements Validator {
  constructor() {}
  @Input() productForm: ProductFormType;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const skus: string[] = [];
    this.productForm.pricings.forEach((pricing) => {
      pricing.options.forEach((option) => {
        if (option.sku) {
          skus.push(option.sku);
        }
      });
    });

    if (value && skus.includes(value) && control.dirty) {
      return { duplicated: true };
    }
    return null;
  }
}
