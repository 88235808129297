import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IShopInfo } from '../models/interfaces/shop';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private isSidebar$ = new BehaviorSubject<boolean>(false);
  private isSuspension$ = new BehaviorSubject<boolean>(false);
  private shopData$ = new BehaviorSubject<IShopInfo | null>(null);
  constructor() {}

  getIsSidebar(): Observable<boolean> {
    return this.isSidebar$.asObservable();
  }

  setIsSidebar(isSidebar: boolean) {
    this.isSidebar$.next(isSidebar);
  }

  getIsSuspension(): Observable<boolean> {
    return this.isSuspension$.asObservable();
  }

  setIsSuspension(isSuspension: boolean) {
    this.isSuspension$.next(isSuspension);
  }

  getShopData(): Observable<IShopInfo | null> {
    return this.shopData$.asObservable();
  }

  setShopData(data: IShopInfo) {
    this.shopData$.next(data);
  }
}
