<ng-container *ngIf="!loading; else loadingTemplate">
  <ng-container *ngIf="userInfo && token; else userTemplate">
    <ng-container *ngIf="infoLive; else infoLiveTemplate">
      <lib-livestream-view
        *ngIf="!isComingSoon"
        [data]="infoLive"
        [userInfo]="userInfo"
        [apiHost]="BE_URL"
        [token]="token"
        (exit)="onExitLive()"
        (eCommerceMessage)="eCommerceMessage($event)"
      ></lib-livestream-view>

      <div
        class="w-screen h-screen flex items-center justify-center"
        *ngIf="isComingSoon"
      >
        <div class="fixed top-0 left-0 w-full h-full bg-gray-900/80 z-10"></div>
        <app-custom-image
          class="fixed top-0 left-0 w-full h-full object-cover"
          [src]="banner"
          alt="Banner livestream"
        />
        <div
          class="flex flex-col gap-6 items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20"
        >
          <img src="/assets/imgs/announcement.svg" alt="Coming soon" />
          <div class="text-sm font-medium text-white">
            {{ comingSoonMessage }}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingTemplate>
  <div
    class="absolute z-10 flex top-0 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50 bg-white"
  >
    <div class="flex justify-center w-full">
      <p-progressBar
        mode="indeterminate"
        [style]="{ height: '6px', width: '240px' }"
      ></p-progressBar>
    </div>
  </div>
</ng-template>

<ng-template #userTemplate>
  <div class="w-screen h-screen flex items-center justify-center">
    <div class="fixed top-0 left-0 w-full h-full bg-gray-900/80 z-10"></div>
    <div
      class="flex flex-col gap-6 items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20"
    >
      <div
        class="text-xl font-medium w-full flex items-center justify-center text-white max-w-[444px] text-center"
      >
        {{ translatePrefix + "please-login" | translate }}
      </div>

      <div class="flex items-center gap-2">
        <app-button
          variant="light"
          [label]="'section-action.sign-up' | translate | sentenceCase"
          (onClick)="onSignUp()"
        ></app-button>
        <app-button
          [label]="'section-action.sign-in' | translate | sentenceCase"
          (onClick)="onSignIn()"
        ></app-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoLiveTemplate>
  <div class="w-screen h-screen flex items-center justify-center">
    <div class="fixed top-0 left-0 w-full h-full bg-gray-900/80 z-10"></div>
    <div
      class="flex flex-col gap-6 items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20"
    >
      <div
        class="text-sm font-semibold w-full h-screen flex items-center justify-center text-white"
      >
        {{ translatePrefix + "live-exist" | translate }}
      </div>
    </div>
  </div>
</ng-template>


