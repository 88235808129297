import { ExportAttributeEnum, SortEnum, TabEnum } from '../enum/product.enum';

export const ProductSortConfig = [
  {
    name: 'a-to-z',
    value: SortEnum.NAME_ASC,
    icon: 'pi-sort-alpha-up-alt',
  },
  {
    name: 'z-to-a',
    value: SortEnum.NAME_DESC,
    icon: 'pi-sort-alpha-down-alt',
  },
  {
    name: 'price-increase',
    value: SortEnum.PRICE_ASC,
    icon: 'pi-sort-amount-up',
  },
  {
    name: 'price-decrease',
    value: SortEnum.PRICE_DESC,
    icon: 'pi-sort-amount-down',
  },
  {
    name: 'quantity-increase',
    value: SortEnum.QUANTITY_ASC,
    icon: 'pi-sort-numeric-up-alt',
  },
  {
    name: 'quantity-decrease',
    value: SortEnum.QUANTITY_DESC,
    icon: 'pi-sort-numeric-down-alt',
  },
];

export const ProductTabConfig = [
  {
    title: 'All',
    quantity: 0,
    value: TabEnum.ALL,
  },
  {
    title: 'Active',
    value: TabEnum.ACTIVE,
    quantity: 0,
  },
  {
    title: 'Sale direct',
    value: TabEnum.SALE_DIRECT,
    quantity: 0,
  },
  {
    title: 'Out of stock',
    value: TabEnum.OUT_OF_STOCK,
    quantity: 0,
  },
  {
    title: 'Draft',
    value: TabEnum.DRAFT,
    quantity: 0,
  },

  {
    title: 'Trash',
    value: TabEnum.TRASH,
    quantity: 0,
  },
  {
    title: 'In review',
    value: TabEnum.IN_REVIEW,
    quantity: 0,
  },

  {
    title: 'Blocked',
    value: TabEnum.BLOCKED,
    quantity: 0,
  },

  {
    title: 'Warning',
    value: TabEnum.WARNING,
    quantity: 0,
  },
];

export const AttributeOptionsConfig = [
  {
    title: 'general-info',
    value: ExportAttributeEnum.GENERAL,
    description: 'general-info-des',
  },
  {
    title: 'quantity-and-selling',
    value: ExportAttributeEnum.QUANTITY_AND_SELLING_PRICE,
  },
  {
    title: 'weight-and-shipping',
    value: ExportAttributeEnum.SHIPMENT,
  },
  {
    title: 'image-product-and-variant',
    value: ExportAttributeEnum.PRODUCT_AND_VARIANT_IMAGES
  },
];

export const ColorsConfig = [
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
];
