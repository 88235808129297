import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() disabled: boolean = false;
  @Input() type = '';
  @Input() label!: string;
  @Input() variant: 'primary' | 'light' | 'info' | 'outline' | 'danger' | 'outline-primary' = 'primary';
  @Input() styleClass = '';
  @Input() icon = '';
  @Input() iconPosition: 'right' | 'left' = 'right';
  @Input() iconClass: string = '';
  @Input() loading = false;
  @Output() onClick = new EventEmitter();
}
