<div class="grid gap-y-4 text-sm">
  <div class="flex gap-4 items-center">
    <ng-content></ng-content>

    <div class="flex flex-col w-full">
      <div class="w-full grid gap-y-1.5">
        <label class="text-gray-700 font-medium" appAsterisk>
          {{
            categoryPrefix + (selectedNode?.parent_id ? "sub-category-name" : "category-name")
              | translate
              | sentenceCase
          }}
        </label>

        <input
          [(ngModel)]="categoryName"
          (input)="categoryNameChange($event)"
          type="text"
          pInputText
          class="flex items-center py-2.5 px-3.5 text-gray-900 border border-gray-300 rounded-lg"
          [placeholder]="
            categoryDes + (selectedNode?.parent_id ? 'enter-sub-category-name' : 'enter-category-name')
              | translate
              | sentenceCase
          "
          maxlength="30"
        />
        <small class="text-end text-xs"> {{ categoryName.length }}/30 {{ "common.characters" | translate }} </small>
      </div>

      <div class="flex gap-4">
        <div class="flex-1 w-full grid gap-y-2 relative">
          <label class="text-gray-700 font-medium" appAsterisk>
            {{ categoryPrefix + "tax" | translate | sentenceCase }}
          </label>
          <input
            [(ngModel)]="categoryTax"
            placeholder="0.00"
            styleClass="h-12 w-full"
            [min]="0"
            [max]="100"
            maxlength="3"
            pInputText
            class="flex items-center py-2.5 px-3.5 text-gray-900 border border-gray-300 rounded-lg"
            (input)="categoryTaxChange($event)"
            type="number"
          />
          <small class="text-end text-lg absolute top-[50%] right-3"> % </small>
          <small class="text-end text-xs text-error-500" *ngIf="checkCatagoryTax">
            {{ categoryPrefix + "check-tax" | translate | sentenceCase }}
          </small>
        </div>

        <div *ngIf="selectedNode?.code" class="flex-1 gap-2 flex flex-col">
          <label class="text-gray-700 font-medium">
            {{ categoryPrefix + "category-code" | translate | sentenceCase }}
          </label>
          <div class="relative">
            <input class="w-full" [value]="selectedNode?.code" type="text" pInputText readonly />
            <i
              *ngIf="selectedNode?.code"
              class="pi pi-clone -scale-x-100 text-primary-700 cursor-pointer px-1 absolute right-2 top-1/2 -translate-y-1/2"
              style="font-weight: bold"
              (click)="copyToClipBoard(selectedNode?.code || '')"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid gap-y-1.5">
    <label class="text-gray-700 font-medium">
      {{ categoryPrefix + "category-type" | translate | sentenceCase }}
    </label>

    <div class="relative">
      <div
        (click)="this.isSelectionDropped = !this.isSelectionDropped"
        class="flex justify-between items-center px-3 py-2 border border-gray-300 rounded-lg hover:cursor-pointer hover:shadow-md"
      >
        <span>
          <span *ngIf="!breadCrumbNames.length" class="text-gray-500/80">
            {{ categoryDes + "select-type" | translate | sentenceCase }}
          </span>
          <span *ngIf="breadCrumbNames.length" class="text-gray-900 font-normal">
            <span>{{ breadCrumbNames.join(" > ") }}</span>
          </span>
        </span>
        <span class="flex gap-3 items-center">
          <i
            *ngIf="breadCrumbNames.length"
            (click)="selectCategory($event, undefined)"
            class="pi pi-times text-gray-500/80 hover:text-gray-900"
          ></i>
          <i class="pi pi-angle-down"></i>
        </span>
      </div>

      <div
        *ngIf="isSelectionDropped"
        class="flex absolute z-[99999] top-full h-[25rem] w-full overflow-x-auto overflow-y-clip border bg-white border-gray-200 shadow-lg"
      >
        <div *ngFor="let categories of categoriesGlobalList" class="overflow-auto border-r border-gray-200 w-1/5">
          <div
            *ngFor="let category of categories"
            (mouseover)="hoverCategory($event, category)"
            (click)="selectCategory($event, category)"
            class="flex justify-between items-center p-2 gap-2 hover:bg-primary-50 hover:text-primary-700 hover:cursor-pointer"
          >
            <span>{{ category.name }}</span>
            <i *ngIf="category.sub_catalogs" class="pi pi-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex items-start gap-2 p-4 bg-primary-50 self-stretch">
    <span class="text-primary-600"><i class="pi pi-info-circle"></i></span>
    <span class="text-primary-700">
      {{ categoryDes + "select-category-tooltip" | translate | sentenceCase }}
    </span>
  </div>
</div>
