<!-- TOP-LEFT -->
<p-toast [position]="'top-right'" [styleClass]="'right-1 max-w-[100vw]'">
  <ng-template let-message pTemplate="message">
    <ng-container
      [ngTemplateOutletContext]="{ message: message }"
      [ngTemplateOutlet]="messageTemplate"
    ></ng-container>
  </ng-template>
</p-toast>

<!-- TOP-CENTER -->
<p-toast [position]="'top-center'" key="tc" [styleClass]="'max-w-[100vw]'">
  <ng-template let-message pTemplate="message">
    <ng-container
      [ngTemplateOutletContext]="{ message: message }"
      [ngTemplateOutlet]="messageTemplate"
    ></ng-container>
  </ng-template>
</p-toast>

<!-- TEMPLATE -->
<ng-template #messageTemplate let-message="message">
  <div class="flex items-center gap-3">
    <i
      class="text-xl/5"
      [ngClass]="{
        'sctr-icon-check': message.severity === 'info',
        'sctr-icon-alert-triangle': message.severity === 'warn',
        'sctr-icon-x-circle': message.severity === 'error',
        'sctr-icon-check-circle': message.severity === 'success'
      }"
    ></i>
    <div class="grid text-sm toast-content">
      <span class="font-semibold">
        {{ message.summary }}
      </span>
      <span>
        {{ message.detail }}
      </span>
    </div>
  </div>
</ng-template>
