<div [ngClass]="length > LIMIT_DESCRIPTION ? 'editor-error' : ''">
  <div *ngIf="isViewMode" [innerHTML]="productForm.description"></div>
  <p-editor
    #editor
    *ngIf="!isViewMode"
    (onTextChange)="onChangeTextEditor($event)"
    [(ngModel)]="productForm.description"
    name="description"
    class="editor"
    [placeholder]="managementPrefix + 'enter-description' | translate | sentenceCase"
    [style]="{ minHeight: '200px' }"
    [readonly]="isViewMode"
  >
    <ng-template pTemplate="header">
      <span class="ql-formats">
        <button type="button" class="ql-bold" aria-label="Bold"></button>
        <button type="button" class="ql-italic" aria-label="Italic"></button>
        <button type="button" class="ql-underline" aria-label="Underline"></button>
      </span>
      <span class="ql-formats">
        <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
          <option *ngFor="let color of colors" [value]="color" [label]="color"></option>
        </select>
        <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
          <option *ngFor="let color of colors" [value]="color" [label]="color"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button value="ordered" type="button" class="ql-list" aria-label="Ordered List"></button>
        <button value="bullet" aria-label="Unordered List" type="button" class="ql-list"></button>
      </span>
      <span class="ql-formats">
        <button type="button" class="ql-link" aria-label="Insert Link"></button>
        <button type="button" class="ql-image" aria-label="Insert Image"></button>
      </span>
    </ng-template>
  </p-editor>

  <div *ngIf="!isViewMode && isHint" class="flex justify-between mt-2">
    <small
      id="name-help"
      class="flex justify-end text-xs"
      [ngClass]="length > LIMIT_DESCRIPTION ? 'text-error-500' : 'text-gray-500'"
    >
      {{ length }}/{{ LIMIT_DESCRIPTION }} {{ "common.characters" | translate }}
    </small>
  </div>
</div>
