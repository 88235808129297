export const convertCategoriesToTree = (inputArray: any): any => {
  const map = new Map();
  const result: any = [];

  inputArray.forEach((item: any) => {
    map.set(item.id, { ...item, label: item?.name, children: [] });
  });

  inputArray.forEach((item: any) => {
    const parent = map.get(item.parent_id);
    if (parent) {
      parent.children.push(map.get(item.id));
    } else {
      result.push(map.get(item.id));
    }
  });
  return result;
}
