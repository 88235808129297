import { Component, Input, OnInit } from '@angular/core';
import { ProductFormType } from '../../pages/product-edit/product-edit.model';
import { ControlContainer, NgForm } from '@angular/forms';
import { ShopAddressControllerService } from '@soctrip/angular-shop-service';

@Component({
  selector: 'app-shipping-setting',
  templateUrl: './shipping-setting.component.html',
  styleUrls: ['./shipping-setting.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ShippingSettingComponent implements OnInit {
  @Input() isViewMode: boolean = false;
  @Input({ required: true }) productForm: ProductFormType;
  @Input() shopId: string = '';

  protected readonly managementPrefix = 'section-product-n-food-management.';
  protected readonly descriptionPrefix = 'text-description.';

  isLoading = false;
  addressList: any[] = [];

  constructor(private shopAddressService: ShopAddressControllerService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.shopAddressService.shopAddressesObjectIdBranchesGet(this.shopId).subscribe({
      next: (res) => {
        this.addressList = res.data;
        this.isLoading = false;
      },
    });
  }
}
