export enum DecorationIdEnum {
    EDIT = 'shop-decoration-edit',
    THEME = 'shop-decoration-theme',
    UPLOADCOVER = 'shop-decoration-upload-cover',
    UPLOADAVATAR = 'shop-decoration-upload-avatar',
    // ENTERSHOPNAME = 'shop-decoration-enter-shop-name',
    ENTERDESCRIPTION = 'shop-decoration-enter-description',
    NEWTAB = 'shop-decoration-create-new-tab',
    CLICKTABNAME = 'shop-decoration-click-tab-name',
    DELETETABNAME = 'shop-decoration-delete-tab-name',
    DRAGANDROPBLOCK = 'shop-decoration-drag-and-drop-block',
    DRAGANDROPBLOCKELEMENT = 'shop-decoration-drag-and-drop-block-element',
    UPLOADIMAGE = 'shop-decoration-upload-image',
    SAVE = 'shop-decoration-save',
    UPLOADBANNER = 'UPLOAD_IMAGE',
    DESCRIPTION = 'DESCRIPTION',
    OPENMENU = 'OPEN_MENU',
    OPENDROPDOWN = 'OPEN_DROPDOWN',
    APPLYTHEMEORCANCELPREVIEW = 'APPLYTHEME_OR_CANCELPREVIEW',
    APPLYTHEME = 'APPLY_THEME',
    CANCELPREVIEW = 'CANCEL_PREVIEW'
}
