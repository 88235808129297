<ng-container [ngSwitch]="attachment?.type">
  <img
    *ngSwitchCase="'image'"
    class="w-full h-full object-cover"
    [src]="attachment.data"
    alt="Attachment"
  />

  <!-- <video muted class="w-full h-full" *ngSwitchCase="'video'">
    <source [src]="attachment.data" type="video/mp4">
  </video> -->

  <!-- Server -->
  <ng-container *ngSwitchCase="'server'">
    <app-custom-image class="w-full h-full object-cover" alt="Attachment" [src]="attachment.data?.id"></app-custom-image>
    <!-- <video muted *ngIf="checkIsVideo(attachment.data)" class="w-full h-full">
      <source [src]="fileService.getVideoSrc(attachment.data?.id)" [type]="attachment.data.type">
    </video> -->
  </ng-container>

  <!-- Link -->
  <ng-container *ngSwitchCase="'link'">
    <img
      *ngIf="checkIsImage(attachment.data)"
      class="w-full h-full object-cover"
      [src]="attachment.data?.id"
      alt="Attachment"
    />
    <ng-container *ngIf="fileService.isVideoUrl(attachment.data?.id)">
      <video
        *ngIf="isControlsVideo"
        [controls]="isControlsVideo"
        class="w-full h-full"
      >
        <source [src]="attachment.data?.id" type="video/mp4" />
        <source [src]="attachment.data?.id" type="video/webm" />
      </video>
      <!-- <img *ngIf="!isControlsVideo" [src]="fileService.convertWebmToWebp(attachment.data?.id)"
        class="w-full h-full object-cover" /> -->
      <app-custom-image
        class="h-full"
        *ngIf="!isControlsVideo"
        [src]="attachment?.data?.id"
        alt="Attachment"
      ></app-custom-image>
    </ng-container>

    <div class="w-full h-full relative">
      <ng-container *ngIf="fileService.isYoutubeUrl(attachment.data?.id)">
        <iframe
          *ngIf="isControlsVideo"
          class="w-full h-full"
          [src]="getSrcYoutube(attachment.data?.id)"
        ></iframe>
        <img
          class="w-full h-full object-cover"
          *ngIf="!isControlsVideo"
          [src]="fileService.getThumbnailYoutube(attachment.data?.id)"
          alt="Thumbnail Youtube"
        />
      </ng-container>
    </div>
  </ng-container>
</ng-container>
