import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomChatService {
  private api = `${environment.BE_URL}chat/sessions/support/agency/`;
  private chatDataSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getPendingChat(shopId: string, module: string, status: string) {
    return this.http.get<any>(`${this.api}${shopId}/status/${status}/count?module=${module}`);
  }

  emitChatData(isReload: boolean): void {
    this.chatDataSubject.next(isReload);
  }

  getChatCountEvent(): Observable<any> {
    return this.chatDataSubject.asObservable();
  }
}
