import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnChanges {
  @Input() showText: boolean = false;
  @Input() multiple: boolean = false;
  @Input() imageMimeTypes = [
    'image/png', // PNG
    'image/jpeg', // JPG
    // 'image/svg+xml', // SVG
    // 'image/gif', // GIF
  ];
  @Input() file?: File;
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Input() url: string | ArrayBuffer | undefined = undefined;
  @Output() urlChange: EventEmitter<any> = new EventEmitter();
  @Output() removeImage: EventEmitter<any> = new EventEmitter();

  isError = false;
  textDescription = 'text-description.';
  defaultUrl = '/assets/imgs/image.webp';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['file'] || changes['url']) this.isError = false;
  }

  setFile(file?: File) {
    this.file = file;
    this.fileChange.emit(file);
  }

  setUrl(url: string) {
    this.url = url;
    this.urlChange.emit(url);
  }

  convertToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => this.setUrl(event.target.result as string);
  }

  selectFile(event: any) {
    const file = event.target.files[0];
    this.handleCheckFile(file);
  }

  handleDrop(files: any) {
    if (files.length === 1) {
      const file = files[0];
      this.handleCheckFile(file);
    } else this.isError = false;
  }

  getFileSizeKB(file: File | undefined): string {
    if (file) return (file.size / 1000).toFixed(1).toString();
    return '';
  }

  isImageFile(file: File | undefined = this.file): boolean {
    if (file) return this.imageMimeTypes.includes(file.type);
    return false;
  }

  removeFile() {
    this.setFile(undefined);
    this.setUrl('');
    this.removeImage.emit();
  }

  handleCheckFile(file: File | undefined) {
    if (!this.isImageFile(file)) {
      this.isError = true;
      return;
    }
    this.isError = false;
    this.setFile(file);
    this.convertToBase64(this.file!);
  }

  emitData() {
    this.fileChange.emit(this.file);
    this.urlChange.emit(this.url);
  }
}
