import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-iframe-approval-product',
  templateUrl: './iframe-approval-product.component.html',
  styleUrls: ['./iframe-approval-product.component.scss'],
})
export class IframeApprovalProductComponent implements OnInit {
  isApproval: boolean = false;

  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    const approval = this.route.snapshot.queryParamMap.get('approval');
    this.isApproval = approval === 'true';
  }
}
