import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[salePriceValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SalePriceValidatorDirective,
      multi: true,
    },
  ],
})
export class SalePriceValidatorDirective {
  @Input() price: number;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value && value > this.price && control.dirty) {
      return {
        greater: true,
      };
    }
    return null;
  }
}
