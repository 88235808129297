import { Directive, Input } from '@angular/core';
import { ProductFormType } from '../pages/product-edit/product-edit.model';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[variantValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: VariantValidatorDirective,
      multi: true,
    },
  ],
})
export class VariantValidatorDirective {
  @Input() productForm: ProductFormType;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const variants = this.productForm.variants.map((variant) => variant.key);

    if (value && variants.includes(value) && variants.length == 2 && control.dirty) {
      return { duplicated: true };
    }
    return null;
  }
}
