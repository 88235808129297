import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { Location } from '@angular/common';
import { Menu } from 'primeng/menu';
import { ShopCatalogControllerService } from '@soctrip/angular-catalog-service';
import { ErrorHandler } from 'src/app/core/utils/error-handler.util';
import { TranslateService } from '@ngx-translate/core';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { FileService } from 'src/app/core/services/file.service';
import { Toast } from 'src/app/core/utils/toast.util';
import { Clipboard } from 'src/app/core/utils/clipboard.util';
import { Category } from '../../models/category';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-pro-cat-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss'],
})
export class CategoriesListComponent {
  @Input() categoriesData: Category[] = [];
  @Input() selectedNode: TreeNode;
  @Output() selectedNodeChange = new EventEmitter();
  @Output() nodeAction = new EventEmitter();
  @Output() onSortCategory = new EventEmitter();

  shopId: string = ''
  toast = inject(Toast);
  location = inject(Location);
  shopCatalogService = inject(ShopCatalogControllerService);
  tempNode?: TreeNode;
  errorHandler = inject(ErrorHandler);
  translator = inject(CustomTranslateService);
  translate = inject(TranslateService);
  fileService = inject(FileService);

  categoryPrefix = 'section-product-n-food-category.';
  categoryDescription = `${this.categoryPrefix}text-description.`;
  actionPrefix = 'section-action.';
  statusPrefix = 'section-status.';

  isCategoryDialogVisible = false;

  items: MenuItem[] = this.getMenuItemData();

  constructor(private clipBoard: Clipboard, private appService: AppService) {
    this.clipBoard = new Clipboard(inject(Toast));
    this.translate.onLangChange.subscribe(() => {
      this.items = this.getMenuItemData(); // Put the items initialization to the referenced method so that it can be called multiple times
    });
  }

  ngOnInit() {
    this.appService.getShopData().subscribe((data) => {
      if (data?.id) {
        this.shopId = data.id;
      }
    });
  }

  selectNode(node: any) {
    this.selectedNode = node;
    this.updateIdURL(node.id);
    this.selectedNodeChange.emit(this.selectedNode);
  }

  updateIdURL(id: string) {
    this.location.replaceState(`/shops/${this.shopId}/product-categories/detail/${id}`);
  }

  handleNodeSelect(event: any) {
    const element = (event?.target || event?.originalEvent?.target) as HTMLElement;
    if (element.getAttribute('data-unselectNode')) return;

    this.selectNode(event.node);
  }

  // Prevent event firing to select the node
  openMenuCommand(event: any, node: any, menu: Menu) {
    // this.stopPropagation(event);
    menu.toggle(event);
    this.tempNode = node;
  }

  // Using stopPropagation to prevent emitting selected node unexpectedly
  handleCommand(event: any, key: string, node: any) {
    this.stopPropagation(event);
    const commandData = { key: key, node: node, list: this.categoriesData };
    switch (commandData.key) {
      case 'add':
        this.nodeAction.emit(commandData);
        break;
      case 'edit':
        this.nodeAction.emit(commandData);
        break;
      case 'delete':
        this.nodeAction.emit(commandData);
        break;
    }
  }

  handlePublicToggle(event: any, node: any) {
    this.stopPropagation(event);
    node.is_used = !node.is_used;

    const sendData = {
      name: node.name,
      global_id: node.global_id,
      is_used: node.is_used,
    };
    this.shopCatalogService.shopCatalogsIdPut(sendData, node.id).subscribe({
      next: (response: any) => {
        const { data, error, success } = response;
        if (success) {
          const isPublic = data.is_used;

          // Recursively set public status to the related nodes
          this.setChildPublicToggle(node, isPublic);
          this.setParentPublicToggle(node, isPublic);

          const title = `${this.statusPrefix}completed`;
          const message = `${this.categoryDescription}updated-category`;
          this.toast.success(this.translator.sentenceCase(title), this.translator.sentenceCase(message));
        } else this.errorHandler.handle(error);
      },
      error: (error: any) => this.errorHandler.handle(error),
    });
  }

  setParentPublicToggle(node: any, isPublic: boolean) {
    if (!isPublic) return;
    const parent = node.parent;
    if (parent) {
      parent.is_used = isPublic;
      this.setParentPublicToggle(parent, isPublic);
    }
  }

  setChildPublicToggle(node: any, isPublic: boolean) {
    node.children?.forEach((item: any) => {
      item.is_used = isPublic;
      this.setChildPublicToggle(item, isPublic);
    });
  }

  stopPropagation(e: any) {
    e?.stopPropagation?.();
    e?.originalEvent?.stopPropagation?.();
  }

  getMenuItemData() {
    const subCategory = this.translator.sentenceCase(`${this.categoryPrefix}sub-category`);
    const editKeyword = this.translator.sentenceCase(`${this.actionPrefix}edit`);
    const deleteKeyword = this.translator.sentenceCase(`${this.actionPrefix}delete`);
    return [
      {
        label: subCategory,
        icon: 'pi pi-plus',
        command: (event: any) => this.handleCommand(event, 'add', this.tempNode),
      },
      {
        label: editKeyword,
        icon: 'pi pi-pencil',
        command: (event: any) => this.handleCommand(event, 'edit', this.tempNode),
      },
      {
        label: `<span class="text-error-700">${deleteKeyword}</span>`,
        escape: false,
        icon: 'pi pi-fw pi-trash',
        iconClass: '!text-error-700',
        command: (event: any) => this.handleCommand(event, 'delete', this.tempNode),
      },
    ];
  }

  getImgWebp(id: string) {
    const api = this.fileService.getApi();
    return id ? `${api}/files/web/${id}.webp` : '/assets/imgs/default/product.webp';
  }

  copyToClipBoard(e: MouseEvent, code: string) {
    e.stopPropagation();
    this.clipBoard.copyText(code);
  }

  onReorderCategory() {
    const categoriesOrder: string[] = this.categoriesData.map((category) => category.id);
    this.onSortCategory.emit(categoriesOrder);
  }
}
